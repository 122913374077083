import React,{ useState, useEffect, useLayoutEffect, Suspense, lazy } from "react";
import {
//  BrowserRouter as Router,
  HashRouter as Router,
  Route,
  Routes,
  useRoutes,
  useNavigate
} from "react-router-dom";

//import AddedTestOverlay from './components/AddedTestOverlay'


import StartPage from './pages/StartPage'
import TakePicturePage from './pages/TakePicturePage';

import InstructVideo1 from './pages/InstructVideo1'
import InstructVideo2 from './pages/InstructVideo2'
import InstructVideo3 from './pages/InstructVideo3'

import EditPicturePage from './pages/EditPicturePage';
import EditSkinTonePage from './pages/EditSkinTonePage';
import EditMoustachePage from './pages/EditMoustachePage';
import EditNamePage from './pages/EditNamePage'
import EditBirthDatePage from './pages/EditBirthDatePage'

import EditNameTestPage from './pages/EditNameTestPage'
import ShowStoriesPage from './pages/ShowStoriesPage'

import Test01Page from './pages/Test01Page';
import Test02Page from './pages/Test02Page';
import Test03Page from './pages/Test03Page'
import Test04Page from './pages/ShowStoriesPageTest'

//import TestLottie from './pages/TestLottie'

import OverlaySpriteAnimation from './components/OverlaySpriteAnimation'
import OverlayUploadServer from './components/OverlayUploadServer'
import OverlayUploadServerError from './components/OverlayUploadServerError'
import ScreenRotateMobile from './components/ScreenRotateMobile'


let isTesting = false, domName = window.location.hostname.toLowerCase()
if(domName.indexOf("lentfertnuc") != -1 || domName.indexOf("bitmove") != -1) isTesting = true

function RoutingTest() {
  let navigate = useNavigate();

  const closeError = () =>{
    navigate("/takepicture")
  }

  return (
    <>
{/*      { isTesting && <AddedTestOverlay />} */}
            <Routes>
              <Route exact path="/" element={<StartPage />} />

              <Route exact path="/test01" element={<Test01Page />} />
              <Route exact path="/test02" element={<Test02Page />} />
              <Route exact path="/test03" element={<Test03Page />} />
              <Route exact path="/test04" element={<Test04Page />} />
              
              <Route exact path="/takepicture" element={<TakePicturePage />} />

              <Route exact path="/video1" element={<InstructVideo1 />} />
              <Route exact path="/video2" element={<InstructVideo2 />} />
              <Route exact path="/video3" element={<InstructVideo3 />} />

              <Route exact path="/editskintone" element={<EditSkinTonePage />} />
              <Route exact path="/editpicture" element={<EditPicturePage />} />
              <Route exact path="/editmoustache" element={<EditMoustachePage />} />
              <Route exact path="/editname" element={<EditNamePage />} />
              <Route exact path="/editbirthdate" element={<EditBirthDatePage />} />
              <Route exact path="/showstories" element={<ShowStoriesPage />} />
              <Route exact path="/s" element={<ShowStoriesPage usetestimg={1} />} />

              <Route exact path="/editnametest" element={<EditNameTestPage />} />
{/*
              <Route exact path="/testlottie1" element={<TestLottie />} />
*/}
            </Routes>
            
          {/* overlay when uploading the image to the server */}
          <OverlaySpriteAnimation />
          <OverlayUploadServer />
          <OverlayUploadServerError onClose={closeError} />
          <ScreenRotateMobile />
{/*
          <ScreenRotationMessage />
*/}
    </>
  );
}

export default RoutingTest;
