import React,{ useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";

import { gsap } from "gsap/all";
import { v4 as uuidv4 } from 'uuid';

import { useLoadApi } from './../store/preloaderstore'
import ManipulationSceneCamera from "./../components/ManipulationSceneCamera"
import RoundedTextCanvas from "./../components/RoundedTextCanvas"

import StdBtnGenericText from './../components/StdBtnGenericText'

import './EditNameTestPage.css';

const axios = require('axios').default;


export default function EditNameTestPage() {
  let navigate = useNavigate();
  let location = useLocation();

  const page = useRef(null);
  const mscene = useRef(null);
  const txtCanv = useRef(null);

  const [namevalue, setNameValue] = useState("JAAP MORETTI");
  
  useEffect(() => {
    setBGRemImage()
    mscene.current.setNameText(namevalue.toUpperCase())
  },[]);

  const setBGRemImage = () => {
    let imgd = useLoadApi.getState().imgData
    if(imgd) {
      mscene.current.createFromBase64( imgd )
    } else {
      //-- check window
      if(window.location.href.indexOf("lentfert")>-1) {
        axios.get('http://lentfertnuc/bmlabel/testimg',{
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(res => {
          if(res.data) {
            if(res.data.hasOwnProperty("code") && res.data.code === 200 ) {
              mscene.current.createFromBase64( res.data.imgdata )
            }
          }

        }).catch((error) => {
          console.log(error);
          return error;
        })
      }
    }
  }

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? window.atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)
    return new Blob([ia], { type: mimeString })
  }

  const onPrevious = (ev) => {
    navigate("/editmoustache")
  }
  const onNext = (ev) => {
    navigate("/editpicture")
  }

  const onChangeName = (e) => {
    setNameValue(e.target.value);
//    txtCanv.current.updateText(e.target.value)
//    mscene.current.updateNameTexture()
      mscene.current.setNameText(e.target.value.toUpperCase())
  }

  //--
  //-- https://github.com/blueimp/JavaScript-Canvas-to-Blob
  //--
  const handleShare = async () => {
    
/*    const newFile = await toBlob(imageRef.current);
    const data = {
      files: [
        new File([newFile], 'image.png', {
          type: newFile.type,
        }),
      ],
      title: 'Image',
      text: 'image',
    };
    
    try {
      if (!navigator.canShare(data)) {
        console.error("Can't share");
      }
      await navigator.share(data);
    } catch (err) {
      console.error(err);
    }
*/
  };

  return (
    <div className="page"  ref={page}>
      
      <div className="name-input">
        <input type="text" placeholder="Type in here" onChange={onChangeName} className="" value={namevalue} checked="" />
      </div>
      
      <ManipulationSceneCamera
        className="cwrapcam"
        foto={false} 
        showLabel={true} 
        yoffset={0} 
        ref={mscene} 
      />
      <RoundedTextCanvas ref={txtCanv} />

      <div className="editbtnwrapper">
        <StdBtnGenericText className="btnprev" caption="PREVIOUS" onClick={onPrevious} />
{/*      
        <StdBtnGenericText className="btnnext" caption="NEXT" onClick={onNext} />
*/}
      </div>
    </div>
  );
}
