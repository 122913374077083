import create from 'zustand'
//import { Loader } from '@pixi/loaders';
import { sound, SoundLoader } from '@pixi/sound';
import * as PIXI from 'pixi.js-legacy'
import { WebfontLoaderPlugin } from "pixi-webfont-loader";


let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream
let isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
let audioExt = isSafari?'.aac':'.mp3'

SoundLoader.add()

PIXI.Loader.registerPlugin(WebfontLoaderPlugin);
//https://fonts.googleapis.com/css2?family=Orbitron:wght@700&display=swap
PIXI.Loader.shared.add({ name: "birraBase", url: "font/Roves Sans Bold Rough.otf" });
PIXI.Loader.shared.add({ name: "ChunkFive", url: "font/ChunkFive-Regular.otf" });
PIXI.Loader.shared.add({ name: "gothamBook", url: "font/Gotham HTF Book.otf" });

const [useLoadApi,loadApi] = create(set => ({
    
    manExtraWidthRelWS:50,

    cameraScaleFactor:0.7,
    cameraTextureScaleFactor:0, //-- screen.width / videoSprite.width

    manScaleFact1:0,
    manScaleFact2:0,
    diffScaleFact:0,

    imgsLU:{},

    startotherapis:0,
    startOtherChecks:() => {
        set( state => ({startotherapis:Math.random()} ))
    },
    isPerson:0,
    setIsPerson:(p) => {
        set( state => ({isPerson:p} ))
    },
    personAge:0,
    setPersonAge:(p) => {
        set( state => ({personAge:p} ))
    },

    imgData:null,
    camPosData:null,

    imgRot:0,
    imgScale:0,
    imgPos:null,

    moustacheRot:0,
    moustacheScale:0,
    moustachePos:null,
    
    //-- used for position camera stuff
    manposition:null,
    setManPos:(p) => {
        set( state => ({manposition:p} ))
    },

    //-- 20230616 - testing!
    manposition2:null,
    setManPos2:(p) => {
        set( state => ({manposition2:p} ))
    },

    camSettings:null,
    setCamSettings:(o) => {
        set( state => ({camSettings:o} ))
    },
    
    bottleAnim:[],

    getDestURL:() => {
        let h = window.location.href, dest = "";
        console.log("getDestURL:",h)
        if(h.indexOf("bitmove.tv")>-1) {
            dest = "https://birramoretti.bitmove.tv/bmlabel"
        } else if(h.indexOf("birramoretti.com")>-1){
            dest = "https://birramoretti.com/appXXXX"
        } else if(h.indexOf("lentfert.net")>-1){
            dest = "https://lentfert.net/birramoretti/bmlabel"
        } else if(h.indexOf("lentfert.eu.ngrok.io")>-1) {
            dest = "https://stdlen.eu.ngrok.io/bmlabel"
        } else {
            dest = "http://lentfertnuc/bmlabel"
        }
        return dest;
    },

    urlExists:{},
    loader:null,
    init:( pathname ) => {
        let state = loadApi.getState();
        
        const loader = PIXI.Loader.shared;
        loader.onError.add((err, loader, resource) => {
            console.error("error",err, resource);
            state.onError()
        })
        loader.onProgress.add((event) => {
//            console.log(event)
            state.onProgress(event)
        })
        loader.onLoad.add((loader,resource) => {
            if( state.urlExists[resource.url] === undefined ) {
                state.urlExists[resource.url] = 1
            } else {
                console.error("state.urlExists[resource.url] exists:",resource.url)
            }
        });
       
loader
.add("iconRot","gfx/Editing-Rotate-icon.png")
.add("iconSize","gfx/Editing-icon-resize.png")

.add("emptyPng","gfx/empty.png")
.add("bmMan1","gfx/bm-man-overlay-st1.webp")
.add("bmMan2","gfx/bm-man-overlay-st2.webp")
.add("bmMan3","gfx/bm-man-overlay-st3.webp")
.add("bmMan4","gfx/bm-man-overlay-st4.webp")
.add("bmMan5","gfx/bm-man-overlay-st5.webp")
.add("bmMan6","gfx/bm-man-overlay-st6.webp")
//.add("moustache","gfx/moustache.png")
.add("moustache","gfx/moustache_v2.png")
.add("bmLabelBG","gfx/bg-label.webp")
.add("pufR", "gfx/bottle-loading-right-puf.webp")
.add("pufL", "gfx/bottle-loading-left-puf.webp")
.add("pufB", "gfx/bottle-loading-bot-puf.webp")
.add("bigBottle", "gfx/bottle-big-with-shadow-half.webp")
.add("dalDash", "gfx/dal-dashes.webp")
.add("bgImg","gfx/bg-blurred.webp")

.add("bubTakePic", "gfx/bubble-takepicture-1.webp")
.add("bubPlaceMoustage", "gfx/bubble-place-moustage.webp")

let banim = [];
for(let i=1;i<201;i++) {
    banim.push('gfx/bottle/bottle_'+i+'.png')
    loader.add('bottle'+i, 'gfx/bottle/bottle_'+i+'.png')
}
state.bottleAnim = banim

let wanim = [];
for(let i=0;i<=60;i++) {
    wanim.push('gfx/wait-1/seq_'+i+'.png')
    loader.add('wait'+i, 'gfx/wait-1/seq_'+i+'.png')
}
for(let i=0;i<=8;i++) {
    wanim.push('gfx/wait-2/wseq_'+i+'.png')
    loader.add('wait'+(61+i), 'gfx/wait-2/wseq_'+i+'.png')
}
state.waitAnim = wanim

/*
.add('sfxBottlesRise2', 'sfx/Bottles_Rise2'+audioExt)
.add('Music3', 'music/PranksAndTricks_Main'+audioExt)
*/
        
        loader.load(() => {
            state.onComplete();
        })
        state.loader = loader;
    },
    //-- for testing purposes only!
    langTestRecursive:(state,obj) => {
        for (var k in obj) {
            if (typeof obj[k] == "object" && obj[k] !== null) {
                obj[k] = state.langTestRecursive(state,obj[k]);
            } else {
                obj[k] = "*-" + obj[k]
//                console.log( obj[k] )
            }
        }
        return obj;
    },
    get:(a) => {
        let state = loadApi.getState();
        return state.imgsLU[a];
    },
    onError: ( ev ) => {
        console.log("Error:",ev)
    },
    onFileError: ( ev ) => {
        console.log(ev)
    },
    useSmoothing: false,
    progress:0,
    progressFract:0,
    progressFake:0,
    onProgress: ( ev ) => {
        let state = loadApi.getState();
        set( state => ({progress:(ev.progress).toFixed(0),progressFract:ev.progress} ))
    },
    onFileLoad: ( ev ) => {
    },
    complete:false,
    onComplete: () => {
        set( state => ({complete:true} ))
        set( state => ({canContinue:true} ))
    },
    canContinue:false,
    setCanContinue:(s) => {
        set( state => ({canContinue:s} ))
    },

    showpreprocessing:false,
    showPreProcessing:(s) => {
        set( state => ({showpreprocessing:s} ))
    },

    uploaderror:0,
    uploaderrormsg:"",
    setUploadError:(s,msg) => {
        set( state => ({uploaderror:s,uploaderrormsg:msg} ))
    },
    setUploadErrorMsg:(s) => {
        set( state => ({uploaderrormsg:s} ))
    },

    //-- data retriving
    getQData:(roleid,qnr) => {
        let state = loadApi.getState(), rv = null;
        let qd = state.loader.resources["role"+roleid+"Qdata"];
        if( qd && qd.hasOwnProperty("data")) {
            if(qd.data.hasOwnProperty("qdata")) {
                for(let i=0;i<qd.data.qdata.arr.length;i++) {
                    if( parseInt(qd.data.qdata.arr[i].nr) === parseInt(qnr) ) {
                        rv = qd.data.qdata.arr[i]
                    }
                }
            }
            console.log(rv)
        }
        return rv;
    },

}))

export {useLoadApi,loadApi}
