/*
    
    https://github.com/TJCoding/Enhanced-Image-Colour-Transfer-2
    
*/
import React from 'react';
import { gsap } from "gsap/all";
import * as PIXI from 'pixi.js-legacy'
import { PixiPlugin } from "gsap/PixiPlugin";
//import hotkeys from 'hotkeys-js';
import { isMobile } from 'mobile-device-detect';
import { loadApi } from '../store/preloaderstore'
import { appApi } from '../store/appstore'
import SpeechBubble from './SpeechBubble'

import './WaitingSpriteAnimation.css';

class WaitingSpriteAnimation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.cRef = React.createRef();
        this.bubble = React.createRef();

        this.PConfig = {
            forceCanvas: true,
            backgroundAlpha:0,
            resolution: 1,
            antialias:true,
            autoResize:false,
            autoDensity: false,
            width:window.screen.width,
            height:window.screen.height,
        }
        this.app = new PIXI.Application(this.PConfig);
    }
    
    componentDidMount() {
        console.log("WaitingSpriteAnimation -> componentDidMount")
        
        let ww = window.screen.width, wh = window.screen.height, fact = 1

        try {
            var tsp = PIXI.AnimatedSprite.fromFrames(loadApi.getState().waitAnim);
            tsp.loop = false
            let spW = tsp.width, spH = tsp.height
//            tsp.animationSpeed = 0.5;
            tsp.animationSpeed = 0.4;
            tsp.anchor.set(0.5,1);
            fact = ww / spW
            tsp.width = ww
            tsp.height = tsp.height * fact
            tsp.x = (ww/2);
            tsp.y = (wh/2) + tsp.height/2;
            tsp.gotoAndPlay(0);
            tsp.onComplete=()=>{
//                tsp.currentFrame = tsp.totalFrames - 9
                tsp.gotoAndPlay(tsp.totalFrames - 10)

            }
            this.tsp = tsp
            this.app.stage.addChild(tsp)
        } catch(err){ console.error(err)}
        
        this.cRef.current.appendChild(this.app.view)
        console.log(fact)
        this.bubble.current.setXY(ww*0.24,(wh/2)+(190*fact))
        this.bubble.current.start(0.9)
    }
    componentWillUnmount() {
        if(this.app) {
            this.tsp.stop();
            this.cRef.current.removeChild(this.app.view)
            this.tsp = null;
        }
    }

    render() {
        let mstyle = {}
        if(this.props.zIndex)mstyle.zIndex=this.props.zIndex
        return (
            <div className="wsanim" style={mstyle}>
                
                <SpeechBubble nr={2} className="speechbubbleWA dropshadow-bottom" ref={this.bubble}>
                    <div className="text">
                    Making sure<br />
                    you look<br />
                    fantastic!
                    </div>
                </SpeechBubble>

                <div className="canvaswrap" ref={this.cRef}></div>
            </div>
        )
    }
}

export default WaitingSpriteAnimation;