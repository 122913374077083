import React,{ useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";

import { gsap } from "gsap/all";
import { v4 as uuidv4 } from 'uuid';

import { useLoadApi } from './../store/preloaderstore'
import { appApi } from './../store/appstore'
import ManipulationSceneCameraLabel from "./../components/ManipulationSceneCameraLabel"

import StdBtnPrevArrow from '../components/StdBtnPrevArrow'
import StdBtnGenericText from '../components/StdBtnGenericText'
import TotalStepsBottom from '../components/TotalStepsBottom'
import BackgroundRoundedUnder from '../components/BackgroundRoundedUnder'
import SpeechBubble from '../components/SpeechBubble'
import CheckBoxLabel from '../components/CheckBoxLabel'
import TopSpace from '../components/TopSpace'

import './EditNameTestPage.css';

const axios = require('axios').default;
const ls = require('local-storage');

export default function EditNamePage() {
  let navigate = useNavigate();

  const page = useRef()
  const mscene = useRef(null);
  const bgUnder = useRef(null);
  const bubble2 = useRef(null);
  const badWbubble = useRef(null);
  const tpSpace = useRef(null);
  
  const btnWrap = useRef(null);
  const btnPrev = useRef();
  const btnNext = useRef();

  const [translateName,setTranslateName] = useState(appApi.getState().usenametranslation)
  const [tcolor,setTColor] = useState("#b58500")
  const [namevalue, setNameValue] = useState(appApi.getState().playerName);
//  const [blockednext, setBlockednext] = useState(false);

  useEffect(() => {
    setBGRemImage()
    bgUnder.current.update()
    mscene.current.setNameText(namevalue.toUpperCase() + " MORETTI")
    mscene.current.setYear(appApi.getState().playerYear)
    tpSpace.current.update()
    //    badWbubble.current.show()
    btnNext.current.setBlocked(false)

    adjustIframeHeight()
  },[]);

  const adjustIframeHeight = () => {
    const ph = gsap.getProperty(page.current,"height")
    console.log(ph)
    if(window !== window.parent) gsap.set(window.parent,{"height":ph})
  }

  const setBGRemImage = () => {
    let imgd = useLoadApi.getState().imgData
    if(imgd) {
      mscene.current.createFromBase64( imgd )
    } else {
      let dest=useLoadApi.getState().getDestURL(),uuid=ls.get("uuid"),u
      if( (""+uuid).length > 10 ) u=dest+"/getremfile/rem-"+uuid+".png"
      else if(window.location.href.indexOf("lentfert")>-1) u = 'http://lentfertnuc/bmlabel/testimg'
      axios.get(u,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        if(res.data) {
          if(res.data.hasOwnProperty("code") && res.data.code === 200 ) {
            mscene.current.createFromBase64( res.data.imgdata )
          }
        }
      }).catch((error) => {
        console.log(error);
        return error;
      })
    }
  }

  const checkBadWord = (word) => {
    let rb = false;
/*
    //-- find in word list!
    if( word.toUpperCase() === "SHIT" ) {
      rb = true
    }
*/
    if( appApi.getState().checkSwear(word.trim().toLowerCase()) ) rb = true

    return rb;
  }

  const onChangeName = (e) => {
    let max_chars = 10;
    let name = ""+e.target.value
    if(name.length >= max_chars) {
      setTColor("#ab2328")
    }
    else {
      setTColor("#b58500")
    }
    if(name.length > max_chars) {
      name = name.substr(0, max_chars);
    }
    else if( name.trim().length > 0 ) {

      if( checkBadWord( name ) )
      {
        badWbubble.current.show()
        btnNext.current.setBlocked(true)
      }
      else 
      {
        badWbubble.current.hide()
        btnNext.current.setBlocked(false)
      }
    } else {
      console.log("name.trim().length = ",name.trim().length)
      if( name.trim().length === 0 ) {
        badWbubble.current.hide()
        btnNext.current.setBlocked(true)
      }
    }

    doNameTranslate(name, translateName)
  }

  const doNameTranslate = (n, translate) => {
    let name = n
    let nameTranslated = name;

    appApi.getState().curNameMatchData = null;
    
    //-- translate names
    if( translate ) {
      if( appApi.getState().hasNameMatch(name.toLocaleLowerCase()) ) {
        let nmData = appApi.getState().curNameMatchData
        console.log(nmData)
        nameTranslated = nmData.NT
      }
    }

    setNameValue(name);
    mscene.current.setNameText(nameTranslated.toUpperCase() + " MORETTI")

    //-- store name
    ls.set("playerNameReal",name)
    ls.set("playerName",nameTranslated)

    appApi.getState().playerNameReal = name;
    appApi.getState().playerName = nameTranslated;
  }

  const onUseOwnName = (toggle) => {
    console.log("onUseOwnName:",toggle)
    if( toggle === 1 ) {
      setTranslateName(false)
      appApi.getState().setUseNameTranslation(false)
      doNameTranslate(appApi.getState().playerNameReal,false)
    } else if( toggle === -1 ) {
      setTranslateName(true)
      appApi.getState().setUseNameTranslation(true)
      doNameTranslate(appApi.getState().playerNameReal,true)
    }
  }

  const onPrevious = (ev) => {
    navigate("/editmoustache")
  }
  const onNext = (ev) => {
    navigate("/editbirthdate")
  }

  return (
    <div className="page"  ref={page}>

      <SpeechBubble nr={2} className="speechbubbleBad dropshadow-bottom" ref={badWbubble}>
        <div className="text">
        oops,<br />
        let's keep it<br />
        friendly!
        </div>
      </SpeechBubble>

      <ManipulationSceneCameraLabel
        className="cwraplabel"
        showLabel={true} 
        yoffset={0} 
        renderBottle={0}
        canvwidth={window.screen.width}
        canvheight={window.screen.width+100}
        ref={mscene} 
      />
      
      <TopSpace className="" yOffset={-40} ref={tpSpace} />
      <div className="txtYourName">Your name</div>
      <div className="txtMaxSymbols">*Maximum 10 symbols</div>
      <div className="name-input">
        <input type="text" className="name" placeholder="Type in here" onChange={onChangeName} value={namevalue} checked="" style={{color:tcolor}}/>
        <CheckBoxLabel className="chklabel" isChecked={!translateName} caption="use my real name&nbsp;" onClick={onUseOwnName} />
      </div>

      <div className="skintone-mid">
        <div className="btnstwrapper" ref={btnWrap}>
          <StdBtnPrevArrow className="btnprev dropshadow-bottom" caption="" onClick={onPrevious} ref={btnPrev} />
          <StdBtnGenericText className="btnnext dropshadow-bottom" caption="NEXT" onClick={onNext} ref={btnNext} />
        </div>
      </div>
      <TotalStepsBottom currentStep={3} />
      <br />
      <br />

      <BackgroundRoundedUnder ref={bgUnder} />
      <div className="bg-img-bottle"><img src="gfx/bottle-dripts-bg.webp" className="inner" style={{marginTop:"-150px"}} /></div>

    </div>
  );
}
