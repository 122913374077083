import React from 'react';
//import { courseApi } from './../store/coursestore'
import browser from './browserdetect';
import { gsap } from "gsap/all";

class StdBtnDownloadOrShareText extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isManagingFocus: false,
            rollOver:false,
            tabindex:1,
        };
        this.myRef = React.createRef();
    }

    componentDidMount() {
        if( this.props.fadeIn )
            this.fadeIn()
    }
    componentWillUnmount() {
    }
    
    _onBlur() {
        this._timeoutID = setTimeout(() => {
            if (this.state.isManagingFocus) {
                this.setState({
                    isManagingFocus: false,
                    rollOver: false,
                });
            }
        }, 0);
    }

    _onFocus() {
        clearTimeout(this._timeoutID);
        if (!this.state.isManagingFocus) {
            this.setState({
                isManagingFocus: true,
                rollOver: true,
            });
        }
    }

    _keypressHandler(ev) {
        console.log(ev.keyCode,ev.key)
        if( ev.key == " ") {
            this.myRef.current.click();
        }
    }

    _onClick(ev) {
        ev.preventDefault();
        if(this.props.fadeOut )
            this.fadeOut()

//        courseApi.getState().playSFX("sfxClick1")

        if(this.props.onClick)
            this.props.onClick(ev);
        if(this.props.onMstate)
            this.props.onMstate("click");
    }

    _onMouseOver(ev) {

//        courseApi.getState().playSFX("sfxRollover")

        //-- show over image!
            this.setState({
                rollOver: true,
            });
        if(this.props.onMouseOver)
            this.props.onMouseOver();
        if(this.props.onMstate)
            this.props.onMstate("over");
    }
    _onMouseOut(ev) {
        //-- show normal image!
            this.setState({
                rollOver: false,
            });
        if(this.props.onMouseOut)
            this.props.onMouseOut();
        if(this.props.onMstate)
            this.props.onMstate("out");
    }

    render() {
        let cls = this.props.className, clsExtra = this.props.classNameExtra
        if( this.state.rollOver ) {
            cls += "-over"
        }
        cls += " "+clsExtra

        let ariaHidden = "false"
        if( this.state.tabindex === -1 )
            ariaHidden = "true"
        
        return (
            <a href="#" 
                ref={this.myRef} 
                className={cls} 
                title={this.props.title} 
                onBlur={this._onBlur.bind(this)} 
                onFocus={this._onFocus.bind(this)} 
//                onKeyPress={event => this._keypressHandler(event)} 
                aria-label={this.props.ariaLabel} 
                aria-hidden={ariaHidden} 
                role={this.props.role} 
                tabIndex={this.state.tabindex} 
                onClick={this._onClick.bind(this)} 
                onMouseOver={this._onMouseOver.bind(this)} 
                onMouseOut={this._onMouseOut.bind(this)} 
                >
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    enableBackground="new 0 0 43.3 43.4"
                    version="1.1"
                    viewBox="0 0 43.3 43.4"
                    xmlSpace="preserve"
                    >
                    <path
                        fill="none"
                        stroke="#fff9e0"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                        d="M32.2 22.8L21.8 33.3 11.3 22.8"
                    ></path>
                    <path
                        fill="none"
                        stroke="#fff9e0"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                        d="M21.8 33.3L21.8 2.5"
                    ></path>
                    <path
                        fill="none"
                        stroke="#fff9e0"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                        d="M40.8 35.5L40.8 40.9 2.5 40.9 2.5 35.5"
                    ></path>
                    </svg>
                The Text
            </a>
            )
    }
}

export default StdBtnDownloadOrShareText;