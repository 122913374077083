import React,{ useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import { gsap } from "gsap/all";
//import { SplitText } from "gsap/SplitText";
import { useLoadApi } from '../store/preloaderstore'
import useWindowSize from "../utils/useWindowSize"
import TotalStepsBottom from '../components/TotalStepsBottom'
import ColorTintSelector from '../components/ColorTintSelector'

export default function Test01Page() {
  let navigate = useNavigate();
  let location = useLocation();
  const size = useWindowSize();

  const page = useRef(null);

  useEffect(() => {
    let dest = useLoadApi.getState().getDestURL()
  },[size]);

  return (
    <div className="page" ref={page}>
      
      <div className="border">
      </div>
      <ColorTintSelector />
      <TotalStepsBottom />

    </div>
  );
}

