import React,{ useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import { isMobile } from 'mobile-device-detect';

import { gsap } from "gsap/all";
import { v4 as uuidv4 } from 'uuid';

import { useLoadApi } from '../store/preloaderstore'
//import ManipulationSceneCamera from "../components/ManipulationSceneCamera"
import ManipulationSceneCamera from "../components/ManipulationSceneCameraAll"
import BtnTakePicture from "../components/BtnTakePicture"
import TotalStepsBottom from '../components/TotalStepsBottom'
import BackgroundRoundedUnder from '../components/BackgroundRoundedUnder'
import TopSpace from '../components/TopSpace'
import SpeechBubble from '../components/SpeechBubble'
import { appApi } from "../store/appstore";

//import "./TakePicture.css"

const ls = require('local-storage');
const axios = require('axios').default;

export default function TakePicturePage() {
  let navigate = useNavigate();

  const page = useRef(null);
  const mscene = useRef(null);
  const btnPic = useRef(null);
  const bgUnder = useRef(null);
  const tpSpace = useRef(null);
  const bubble2 = useRef(null);

  const canContinue = useLoadApi(state => state.canContinue)
  
  useEffect(() => {
    try {
      //-- clear all data
      ls.remove("moustachePos")
      ls.remove("headPos")
      ls.remove("moustacheRot")
      ls.remove("headRot")
      ls.remove("moustacheScale")
      ls.remove("headScale")
    } catch(e) { console.log(e) }

    btnPic.current.setBtnState(0)
    bgUnder.current.update()
    tpSpace.current.update()
    
    //    ls.set("skintone",1)
    console.log( "skintone:", ls.get("skintone") )
    
    adjustIframeHeight()
  },[]);

  const adjustIframeHeight = () => {
    const ph = gsap.getProperty(page.current,"height")
    console.log(ph,window.innerHeight)
    if(window !== window.parent) gsap.set(window.parent,{"height":ph})
  }

  //--
  //--  takePicture
  //--
  const takePicture = (ev) => {
    useLoadApi.getState().showPreProcessing(true)
    let photo = mscene.current.takePhoto()
//    console.log(photo)
    if( 1 === 0 ) {
      var tab = window.open('about:blank', '_blank');
      tab.document.write('<image src="'+photo+'" />');
      tab.document.close();
    }
    sendImage( photo )
//    uploadImage( photo )
//    navigate("/video2")
  }
  
  //--
  //--  cleanupOldPhoto
  //--
  const cleanupOldFoto = () => {
    let dest = useLoadApi.getState().getDestURL()
    let uuid = ls.get("uuid")
    if( (""+uuid).length > 10 ) {
      axios.get(dest+'/cleanup-old/'+uuid).then(res => {
        console.log("cleanupOldFoto:",res)
        if(res.data) {
          if(res.data.code === 200 ) {
          }
        }
      }).catch((error) => {
        console.log("cleanupOldFoto -> error:",error);
        return error;
      })
    }
  }
  
  //--
  //--  sendImage
  //--
  const sendImage = ( base64img ) => {
    let dest = useLoadApi.getState().getDestURL()
    const uid = uuidv4()
    //-- store uuid in local storage
    ls.set("uuid", uid)
    const formData = new FormData();
    formData.append('compos', DataURIToBlob(base64img), uid+'.png');
    formData.append('uid', uid);
    axios.post(dest+'/doall', formData,{
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(res => {
      console.log(res)
      let hErr = false,
        edetails = ""
      if(res.data) {
        if(res.data.hasOwnProperty("gerror") ) {
          if(res.data.gerror === 0) {
            if( res.data.doChecks[0] === 1 ) {
              if( res.data.hasOwnProperty("data1") && res.data.data1.hasOwnProperty("hasError") && !res.data.data1.hasError ) {
                if( res.data.data1.isPerson !== 1 ) {
                  appApi.getState().setUploadError("We did not find a person in the picture, please try again")
                  appApi.getState().setUploadErrorNumber(-1)
                  hErr = true
                } else {
                }
              } else if(!hErr) {
                if(res.data.hasOwnProperty("data1") && res.data.data1.hasOwnProperty("hasError") )edetails = res.data.data1.error
                appApi.getState().setUploadError("There was a problem detecting a person [" + edetails + "]")
                appApi.getState().setUploadErrorNumber(-10000)
                hErr = true
              }
            }

            if( res.data.doChecks[1] === 1 ) {
              if( !hErr && res.data.hasOwnProperty("data2") && res.data.data2.hasOwnProperty("hasError") && !res.data.data2.hasError ) {
                if( res.data.data2.age < 18 ) {
                  appApi.getState().setUploadError("You must be 18 years of age or older to continue, age detected [" + res.data.data2.age + "]")
                  hErr = true
                } else {
                }
              } else if(!hErr) {
                if(res.data.hasOwnProperty("data2") && res.data.data2.hasOwnProperty("hasError") )edetails = res.data.data2.error
                appApi.getState().setUploadError("There was a problem detecting your age [" + edetails + "]")
                hErr = true
              }
            }

            if( res.data.doChecks[2] === 1 ) {
              if( !hErr && res.data.hasOwnProperty("data3") && res.data.data3.hasOwnProperty("hasError") && !res.data.data3.hasError ) {
                useLoadApi.getState().imgData = res.data.data3.imgdata
              } else if(!hErr) {
                if(res.data.hasOwnProperty("data3") && res.data.data3.hasOwnProperty("hasError") )edetails = res.data.data3.error
                appApi.getState().setUploadError("There was a problem removing the background [" + edetails + "]")
                appApi.getState().setUploadErrorNumber(-10000)
                hErr = true
              }
            }
            
            useLoadApi.getState().startOtherChecks()
            useLoadApi.getState().showPreProcessing(false)
            
            //-- no error, continue
            if( !hErr ) {
              navigate("/video2")
            }

          } else {
            edetails = "Error code:" + res.data.gerror + " Error:" + res.data.gerrormsg;
            appApi.getState().setUploadError("There was a problem uploading your photo [" + edetails + "]")
            useLoadApi.getState().showPreProcessing(false)
            appApi.getState().setUploadErrorNumber(-10000)
          }
        }
      }
    }).catch((error) => {
      console.log(error);
      appApi.getState().setUploadError("There was a problem [Error:" + error + "]")
      useLoadApi.getState().showPreProcessing(false)
      appApi.getState().setUploadErrorNumber(-10000)
      return error;
    })
  }

  //--
  //--  uploadImage
  //--
  const uploadImage = ( base64img ) => {
    appApi.getState().setUploadMessaging("uploading photo")
    let dest = useLoadApi.getState().getDestURL()
    const uid = uuidv4()
    ls.set("uuid", uid)
    const formData = new FormData();
    formData.append('compos', DataURIToBlob(base64img), uid+'.png');
    formData.append('uid', uid);
    axios.post(dest+'/upload', formData,{headers: {'Content-Type': 'multipart/form-data',},}).then(res => {
      console.log(res)
      if(res.data) {
        if(res.data.hasOwnProperty("code") && res.data.code === 200 ) {
          checkPerson()
        }
      }
    }).catch((error) => {
      console.log(error);

      return error;
    })
  }

  //--
  //--  checkPerson
  //--
  const checkPerson = () => {
    appApi.getState().setUploadMessaging("checking person")
    let uid = ls.get("uuid")
    let dest = useLoadApi.getState().getDestURL()
    axios.post(dest+'/checkperson/'+uid+".png", null,{ headers: {'Content-Type': 'multipart/form-data',},}).then(res => {
      console.log(res)
      if(res.data) {
        if(res.data.hasOwnProperty("code") && res.data.code === 200 ) {
          checkAge()
        }
      }
    }).catch((error) => {
      console.log(error);
      return error;
    })
  }

  //--
  //--  checkAge
  //--
  const checkAge = () => {
    appApi.getState().setUploadMessaging("checking age")
    let uid = ls.get("uuid")
    let dest = useLoadApi.getState().getDestURL()
    axios.post(dest+'/checkage/'+uid+".png", {},{ headers: {'Content-Type': 'multipart/form-data',},}).then(res => {
      console.log(res)
      if(res.data) {
        if(res.data.hasOwnProperty("code") && res.data.code === 200 ) {
          //-- check age of person

        }
      }
    }).catch((error) => {
      console.log(error);
      return error;
    })
  }

  //--
  //--  remBackground
  //--
  const remBackground = () => {
    appApi.getState().setUploadMessaging("removing background")
    let uid = ls.get("uuid")
    let dest = useLoadApi.getState().getDestURL()
    axios.post(dest+'/removebackground/'+uid+".png", {},{ headers: {'Content-Type': 'multipart/form-data',},}).then(res => {
      console.log(res)
      if(res.data) {
        if(res.data.hasOwnProperty("code") && res.data.code === 200 ) {
          useLoadApi.getState().showPreProcessing(false)
          useLoadApi.getState().imgData = res.data.imgdata
          navigate("/video2")
        }
      }
    }).catch((error) => {
      console.log(error);
      return error;
    })
  }

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? window.atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)
    return new Blob([ia], { type: mimeString })
  }

  const onCameraIsReady = () => {
    console.log("onCameraIsReady -> canContinue:",canContinue)
    if(canContinue) {

      btnPic.current.anim()

      gsap.delayedCall(0,()=>{
        btnPic.current.setBtnState(1)
        bubble2.current.start(0.1);
      })
    }
  }

  return (
    <div className="page" ref={page}>

      <SpeechBubble nr={2} className="speechbubble2" ref={bubble2}>
        <div className="text">
        make your<br />
        perfect <br />
        baffo face
        </div>
      </SpeechBubble>

      <ManipulationSceneCamera 
        className="cwrapcam" 
        zIndex={7} 
        foto={true} 
        editmode={false} 
        yoffset={0} 
        ref={mscene} 
        onCameraIsReady={onCameraIsReady} 
      />
      
      <TopSpace className="" ref={tpSpace} />
      <BtnTakePicture className="btnTP" onClick={takePicture} ref={btnPic} />
      <div className="txtTakeFoto">Take your Photo</div>
      <TotalStepsBottom currentStep={2} />
      <br />
      <br />

      <BackgroundRoundedUnder ref={bgUnder} />
      <div className="bg-img-blur"><img src="gfx/bg-blurred.webp" className="inner" style={{marginTop:"-150px"}} /></div>

    </div>
  );
}
