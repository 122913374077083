import React from 'react';
import { loadApi } from './../store/preloaderstore'
import { gsap, Power1 } from "gsap/dist/gsap";
import { isMobile } from 'mobile-device-detect';
import { appApi } from './../store/appstore'

import ColorTintSelectorBtn from './ColorTintSelectorBtn'

import './ColorTintSelector.css';


class ColorTintSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentStep:0,
        }
        
        this.el = React.createRef();
        
        this.p1 = React.createRef();
        this.p2 = React.createRef();
        this.p3 = React.createRef();
        this.p4 = React.createRef();
        this.p5 = React.createRef();
        this.p6 = React.createRef();
        this.btns = [React.createRef(),React.createRef(),React.createRef(),React.createRef(),React.createRef(),React.createRef()]
    }

    componentDidMount() {
        const w = window.innerWidth
        let skintone = appApi.getState().skintone
        this.selectSkintone(skintone)
        
//        gsap.set(this.el.current,{right:"-100px"})
    }
    componentWillUnmount() {
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevState.currentStep !== this.state.currentStep) {
            this.updateAnim()
        }
    }

    selectSkintone(nr) {
        for(let i=0;i<this.btns.length;i++) {
            if(nr === (i+1)) {
                this.btns[i].current.select();
            }
            else {
                this.btns[i].current.deselect();
            }
        }
    }

    updateAnim() {
    }
    
    onClick() {
    }
    onSelect(nr) {
//        console.log("skintone",nr)
        for(let i=0;i<this.btns.length;i++) {
            if(nr !== (i+1))
                this.btns[i].current.deselect();
        }
    }
    
    render() {
        return (
            <div className="cselector" ref={this.el}>
                <ColorTintSelectorBtn nr={1} onSelect={this.onSelect.bind(this)} ref={this.btns[0]} />
                <ColorTintSelectorBtn nr={2} onSelect={this.onSelect.bind(this)} ref={this.btns[1]} />
                <ColorTintSelectorBtn nr={3} onSelect={this.onSelect.bind(this)} ref={this.btns[2]} />
                <ColorTintSelectorBtn nr={4} onSelect={this.onSelect.bind(this)} ref={this.btns[3]} />
                <ColorTintSelectorBtn nr={5} onSelect={this.onSelect.bind(this)} ref={this.btns[4]} />
                <ColorTintSelectorBtn nr={6} onSelect={this.onSelect.bind(this)} ref={this.btns[5]} />
            </div>
        )
    }
}

export default ColorTintSelector;
