import React from 'react';
import { gsap } from "gsap/all";
import { appApi } from '../store/appstore'

import LoadingSpriteAnimation from './LoadingSpriteAnimation'

import './OverlaySpriteAnimation.css';

class OverlaySpriteAnimation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {show:false}
        this.elRef = React.createRef();
    }
    
    componentDidMount() {
      gsap.set(this.elRef.current,{display:"none"})
      
      this.unsub1 = appApi.subscribe(showbottleanim => {
        console.log("showbottleanim:",showbottleanim)
        if(showbottleanim === -1) {
          gsap.set(this.elRef.current,{display:"none"})
          this.setState({show:false})
        } else {
          this.setState({show:true})
          gsap.set(this.elRef.current,{display:"flex"})
        }
      }, state => state.showbottleanim)
    }
    componentWillUnmount() {
      this.unsub1()
    }
    
    render() {
        return (
          <div className="olSpriteAnim" ref={this.elRef}>
            { this.state.show && <>
              <LoadingSpriteAnimation zIndex={2000} />
            </>}
          </div>
      )
    }
}

export default OverlaySpriteAnimation;
