import React from 'react';
import { loadApi } from './../store/preloaderstore'
import { gsap, Power1 } from "gsap/dist/gsap";
import { isMobile } from 'mobile-device-detect';

import './TotalStepsBottom.css';

class TotalStepsBottomItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stepType:0,
        }
        this.el = React.createRef();
        this.elinner = React.createRef();
        this.elbase = React.createRef();
        this.eltext = React.createRef();
    }

    componentDidMount() {
        if(this.props.nr === this.props.currentStep ) {
            this.setState({stepType:1})
            gsap.set(this.elinner.current,{display:"block"});
        }
        else if(this.props.nr < this.props.currentStep ) {
            this.setState({stepType:3})
            gsap.set(this.elinner.current,{display:"block"});
        }
        if(this.props.nr > this.props.currentStep ) {
            this.setState({stepType:2})
        }
    }
    componentWillUnmount() {
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevState.stepType !== this.state.stepType) {
            this.updateAnim()
        }
    }
    updateAnim() {
    }

    setPos(x) {
        gsap.set(this.el.current,{x:x})
    }
    setBasePos(x) {
        gsap.set(this.elbase.current,{x:x})
    }
    setTextPos(x) {
        gsap.set(this.eltext.current,{x:x})
    }
    
    render() {
        return (
            <div className="bulletwrapper" ref={this.el}>
                <div className="bulletouter" ref={this.elinner}>
                    
                    {this.state.stepType === 1 && <div className='inner'></div>}
                    {this.state.stepType === 2 && <></>}
                    {this.state.stepType === 3 && <div className='checkmark'>
                            <svg
                            viewBox="0 0 122.877 101.052"
                            fill="#fffbe2"
                            >
                            <path d="M4.43 63.63A14.383 14.383 0 01.003 53.52a14.393 14.393 0 014.015-10.281 14.372 14.372 0 0110.106-4.425 14.373 14.373 0 0110.283 4.012l24.787 23.851L98.543 3.989l1.768 1.349-1.77-1.355a2.27 2.27 0 01.479-.466A14.383 14.383 0 01109.243.022V.018l.176.016c3.623.24 7.162 1.85 9.775 4.766a14.383 14.383 0 013.662 10.412h.004l-.016.176a14.362 14.362 0 01-4.609 9.632L59.011 97.11l.004.004a2.157 2.157 0 01-.372.368 14.392 14.392 0 01-9.757 3.569 14.381 14.381 0 01-9.741-4.016L4.43 63.63z"></path>
                            </svg>
                        </div>}

                </div>
                <div className='bulletouterbase' ref={this.elbase}></div>
                <div className='text' ref={this.eltext}>{this.props.caption}</div>
            </div>
        )
    }
}

export default TotalStepsBottomItem;
