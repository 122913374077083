import React,{ useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import { gsap } from "gsap/all";
import { useLoadApi } from '../store/preloaderstore'

import WaitingSpriteAnimation from '../components/WaitingSpriteAnimation'

export default function Test03Page() {
  let navigate = useNavigate();
  let location = useLocation();

  return (
    <div>
      <WaitingSpriteAnimation zIndex={2000} />
    </div>
  );
}

