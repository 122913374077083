import React from 'react';
import { gsap } from "gsap/all";
import { loadApi } from '../store/preloaderstore'
import { appApi } from '../store/appstore'
import StdBtnGenericText from '../components/StdBtnGenericText'
import SpeechBubble from '../components/SpeechBubble'


class OverlayUploadServerError extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          show:false,
          uploaderr:0,
          error:"",
        }
        this.elRef = React.createRef();
        this.elBad = React.createRef();
    }
    
    componentDidMount() {
      gsap.set(this.elRef.current,{display:"none"})


      this.unsub1 = appApi.subscribe(uploaderrornum => {
        if( typeof uploaderrornum === 'object' && uploaderrornum !== null ) {
          this.setState({uploaderr:uploaderrornum.num},()=>{
            if(uploaderrornum.num !== 0) {
              gsap.set(this.elRef.current,{display:"grid"})
              this.setState({show:true},()=>{
                this.elBad.current.show(0.1)
              })
            }
            else {
              gsap.set(this.elRef.current,{display:"none"})
              this.setState({show:false})
            } 
          })
        }
      }, state => state.uploaderrornum)
/*
      this.unsub1 = appApi.subscribe(uploaderrormsg => {
        if(uploaderrormsg !== "") {
          gsap.set(this.elRef.current,{display:"grid"})
          this.setState({show:true,error:uploaderrormsg},()=>{
            this.elBad.show(0.1)
          })
        }
        else {
          gsap.set(this.elRef.current,{display:"none"})
          this.setState({show:false})
        }
      }, state => state.uploaderrormsg)
*/

    }
    componentWillUnmount() {
      this.unsub1()
    }
    
    onOK() {
      gsap.set(this.elRef.current,{display:"none"})
      this.setState({show:false},()=>{
        this.elBad.current.hide()
      })
    }

    onCloseClick() {
      this.onOK()
      if(this.props.onClose)this.props.onClose()
    }

    render() {
        return (
          <div className="olUSError" ref={this.elRef}>

          {(this.state.uploaderr === -10000 || this.state.uploaderr === -1) && <SpeechBubble 
            nr={2} 
            className="speechbubbleSrvErr dropshadow-bottom" 
            ref={this.elBad} 
            onCloseClick={this.onCloseClick.bind(this)}
          >
            <div className="text">
            oops, something<br />
            went wrong<br />
            try again!
            </div>
          </SpeechBubble>}
          
          {this.state.uploaderr === -10 && <SpeechBubble 
            nr={2} 
            className="speechbubbleSrvErr dropshadow-bottom" 
            ref={this.elBad} 
            onCloseClick={this.onCloseClick.bind(this)}
          >
            <div className="text">
            oops, where<br />
            are you?<br />
            try again!
            </div>
          </SpeechBubble>}

          {this.state.uploaderr === -100 && <SpeechBubble 
            nr={2} 
            className="speechbubbleSrvErr dropshadow-bottom" 
            ref={this.elBad} 
            onCloseClick={this.onCloseClick.bind(this)}
          >
            <div className="text">
            oops,<br />
            Too young<br />
            try again!
            </div>
          </SpeechBubble>}



{/*
            <div className='title'>Ooops!</div>
            <br />
            {this.state.error}
            <div className="btnstwrapper" >
              <StdBtnGenericText className="btnnext dropshadow-bottom" caption="OK" onClick={this.onOK.bind(this)} />
            </div>
*/}
          </div>
      )
    }
}

export default OverlayUploadServerError;
