import React from 'react';
import { loadApi } from './../store/preloaderstore'
import { gsap, Power1 } from "gsap/dist/gsap";
import { isMobile } from 'mobile-device-detect';

import StdBtnDownloadOrShareBottle from './StdBtnDownloadOrShareBottle'
import StdBtnDownloadOrShareText from './StdBtnDownloadOrShareText'

import './SharePopUp.css';

class SharePopUp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible:false,
        }
        this.el = React.createRef();
        this.inner = React.createRef()
    }

    componentDidMount() {
    }
    componentWillUnmount() {
    }

    show(pageHeight,dims) {
        this.setState({visible:true},()=>{
            gsap.set(this.el.current,{opacity:1,height:pageHeight})
            gsap.set(this.inner.current,{top:dims.y+window.scrollY-100})
        })
    }
    hide() {
        gsap.to(this.el.current,{duration:0.4,opacity:0,onComplete:()=>{
            this.setState({visible:false})
        }})
    }

    onShareBottle() {
        if(this.props.onShareBottle)this.props.onShareBottle()
        this.hide()
    }
    onShareText() {
        if(this.props.onShareText)this.props.onShareText()
        this.hide()
    }
    
    render() {
        if( this.state.visible) {
            return (
                <div className="sharePUP" ref={this.el} onClick={this.hide.bind(this)}>
                    <div className="inner"  ref={this.inner}>
                        <StdBtnDownloadOrShareBottle className="btn-dorshare dropshadow-bottom" onClick={this.onShareBottle.bind(this)} />
                        <StdBtnDownloadOrShareText className="btn-dorshare dropshadow-bottom" onClick={this.onShareText.bind(this)} />
                    </div>
                </div>
            )
        }
        else {
            return (<></>
            )
        }
    }
}

export default SharePopUp;
