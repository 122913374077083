import create from 'zustand'

//-- import swear words all we have!
import swearEN from './../locales/swear/en/translation.json';
import swearHI from './../locales/swear/hi/translation.json';
import swearID from './../locales/swear/id/translation.json';
import swearJA from './../locales/swear/ja/translation.json';
import swearKO from './../locales/swear/ko/translation.json';
import swearNL from './../locales/swear/nl/translation.json';
import swearVI from './../locales/swear/vi/translation.json';
import swearZW from './../locales/swear/zh/translation.json';

//-- import name / story
import storyEN from './../locales/names/en/namestories.json'

const ls = require('local-storage');
if( ls.get("skintone") === null ) {
    ls.set("skintone",3)
}
const stdSkinTone = ls.get("skintone")
console.log("stdSkinTone:",stdSkinTone)
const zeroPad = (num, places) => String(num).padStart(places, '0')
const swearArr = {}
const nameArr = {}
const today = new Date()
const currentDay = zeroPad(today.getDate(),2)
const currentMonth = zeroPad(today.getMonth()+1,2)

const [useAppApi,appApi] = create(set => ({
    
    init:() => {
        let tarr = []
        tarr = swearEN.words.concat(tarr)
        tarr = swearHI.words.concat(tarr)
        tarr = swearID.words.concat(tarr)
        tarr = swearJA.words.concat(tarr)
        tarr = swearKO.words.concat(tarr)
        tarr = swearNL.words.concat(tarr)
        tarr = swearVI.words.concat(tarr)
        tarr = swearZW.words.concat(tarr)
        for(let i=0;i<tarr.length;i++) {
            let a = tarr[i].trim().toLowerCase()
            if(a.length > 0)swearArr[a] = 1
        }
//        console.log(swearArr)

        //-- init name and stories?
        tarr = []
        tarr = storyEN.Names.concat(tarr)
        for(let i=0;i<tarr.length;i++) {
            nameArr[tarr[i].N.toLowerCase()] = tarr[i]
        }
//        console.log(nameArr)

    },

    getBDStory:(bd) => {
        let state = appApi.getState(), 
            a = bd.split("-"),
            d = a[0], 
            m = parseInt(a[1])-1, 
            rv = state.findStoryByDayMonth(d,m)
        return rv
    },

    findStoryByDayMonth:(d,m) => {
        const len = storyEN.Bdays.length, mo = {0:"Jan",1:"Feb",2:"Mar",3:"Apr",4:"May",5:"Jun",6:"Jul",7:"Aug",8:"Sep",9:"Oct",10:"Nov",11:"Dec"};
        let rv = null, ipDM = parseInt(d) + "-" + mo[parseInt(m)];
        for(let i=0;i<len;i++) {
            if( storyEN.Bdays[i].Bd === ipDM ) {
                rv = storyEN.Bdays[i]
                break;
            }
        }
        return rv;
    },
    findStoryByMonth:(m) => {
        const len = storyEN.Months.length, mo = {0:"January",1:"February",2:"March",3:"April",4:"May",5:"June",6:"July",7:"August",8:"September",9:"October",10:"November",11:"December"};
        let rv = null, ipDM = mo[parseInt(m)];
        for(let i=0;i<len;i++) {
            if( storyEN.Months[i].Mo === ipDM ) {
                rv = storyEN.Bdays[i]
                break;
            }
        }
        return rv;
    },
    
    checkSwear:(s) => {
        if( s.length > 0 && swearArr[s] === 1 ) {
            console.log("FOUND:",s,swearArr[s])
            return true
        }
        //-- loop through all words and check if word is in s?
        if( 1 === 1 && s.length > 0) {
            const keys = Object.keys(swearArr)
            for(let i=0;i<keys.length;i++) {
//                if( s.length >= keys[i].length && s.indexOf(keys[i]) > -1 ) {
                if( s.length === keys[i].length && s.indexOf(keys[i]) > -1 ) {
                    console.log("FOUND:",s, keys[i])
                    return true
                }
            }
        }
        return false
    },

    playerName:"BIRRA",
    playerNameReal:"BIRRA",

    loadPlayerName:() => {
        let state = appApi.getState();
        try {
            state.playerName = ls.get("playerNameReal");
            state.playerNameReal = ls.get("playerName");
        } catch( e ) {

        }
    },

    playerYear:"1985",
    playerDate:"1985-" + currentMonth + "-" + currentDay,
    
    curNameMatch:"",
    curNameMatchData:null,
    hasNameMatch:(s) => {
        if( nameArr[s] ) {
            set( state => ({curNameMatch:s,curNameMatchData:nameArr[s]}))
            return true
        }
        return false
    },

    tprogress:1,
    setTProgress:(p) => {
        set( state => ({tprogress:p} ))
    },

    componentready:-1,
    setComponentReady:() => {
        set( state => ({componentready:Math.random()} ))
    },

    skintone: stdSkinTone,     //-- std color of birra moretti
    setSkinTone:(p) => {
        ls.set("skintone",p)
        set( state => ({skintone:p} ))
    },

    usenametranslation:true,
    setUseNameTranslation:(s) =>{
        set( state => ({usenametranslation:s} ))
    },

    showuploadol:-1,
    setShowUploadOL:() =>{
        set( state => ({showuploadol:Math.random()} ))
    },

    showbottleanim:-1,
    setShowBottleAnimOL:(s) =>{
        console.log("setShowBottleAnimOL:",s)
        if(s !== -1) {
            console.log("setShowBottleAnimOL:",s)
            set( state => ({showbottleanim:Math.random()} ))
        } else {
            set( state => ({showbottleanim:-1} ))
        }
    },

    uploadmessaging:"",
    setUploadMessaging:(p) => {
        set( state => ({uploadmessaging:p} ))
    },
    uploaderrormsg:"",
    setUploadError:(p) => {
        set( state => ({uploaderrormsg:p} ))
    },
    uploaderrornum:null,
    setUploadErrorNumber:(p) => {
        set( state => ({uploaderrornum:{num:p}} ))
    },


}))

export {useAppApi,appApi}
