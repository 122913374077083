/*
    
    https://github.com/TJCoding/Enhanced-Image-Colour-Transfer-2
    
*/
import React from 'react';
import { gsap } from "gsap/all";
import * as PIXI from 'pixi.js-legacy'
import { PixiPlugin } from "gsap/PixiPlugin";
//import hotkeys from 'hotkeys-js';
import { isMobile } from 'mobile-device-detect';
import { loadApi } from '../store/preloaderstore'
import { appApi } from '../store/appstore'
import SpeechBubble from '../components/SpeechBubble'

import './LoadingSpriteAnimation.css';

class LoadingSpriteAnimation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.cRef = React.createRef();
        this.bubble = React.createRef();

        if( isMobile ) {
            this.PConfig = {
                forceCanvas: true,
                backgroundAlpha:0,
                resolution: 1,
                antialias:true,
                autoResize:false,
                autoDensity: false,
                width:window.screen.width,
                height:window.screen.height,
            }
        } else {
            this.PConfig = {
                forceCanvas: true,
                backgroundAlpha:0,
                resolution: 1,
                antialias:true,
                autoResize:true,
                autoDensity: false,
                resizeTo:window,
            }
        }

        this.app = new PIXI.Application(this.PConfig);
    }

    componentDidMount() {
        console.log("LoadingSpriteAnimation -> componentDidMount")
        
        try {
            var tsp = PIXI.AnimatedSprite.fromFrames(loadApi.getState().bottleAnim);
            tsp.loop = true
            tsp.animationSpeed = 0.5;
            tsp.anchor.set(0.5,1);
            tsp.x = (window.innerWidth/2);
            tsp.y = (window.innerHeight/2) + tsp.height/2;
            tsp.play();
//            tsp.angle = 10;
            this.app.stage.addChild(tsp)

            var puf1 = PIXI.Sprite.from(PIXI.Loader.shared.resources.pufR.texture);
            puf1.alpha = 0
            puf1.width *= 0.25
            puf1.height *= 0.25
            puf1.x = (window.innerWidth/2);
            puf1.y = (window.innerHeight/2) - puf1.height;
            this.app.stage.addChild(puf1)

            var puf2 = PIXI.Sprite.from(PIXI.Loader.shared.resources.pufL.texture);
            puf2.alpha = 0 
            puf2.width *= 0.25
            puf2.height *= 0.25
            puf2.anchor.set(0.2,0);
            puf2.x = (window.innerWidth/2) - puf2.width;
            puf2.y = (window.innerHeight/2) - puf2.height*0.2;
            this.app.stage.addChild(puf2)

            var puf3 = PIXI.Sprite.from(PIXI.Loader.shared.resources.pufB.texture);
            puf3.width *= 0.25
            puf3.height *= 0.25
            puf3.x = (window.innerWidth/2) - puf2.width/2;
            puf3.y = (window.innerHeight/2) + (tsp.height/2) - 27;
            this.app.stage.addChild(puf3)

        } catch(err){ console.error(err)}

        var tl = gsap.timeline({repeat: -1});
        tl.to(tsp, {angle:4, duration: 1, ease: "circ.out", onStart:()=>{
            //-- puf 1
            gsap.set(puf1, {x:(window.innerWidth/2), y:(window.innerHeight/2) - puf1.height, alpha:0 });
            gsap.to(puf1, {delay:0.2, x:(window.innerWidth/2) + 50, y:(window.innerHeight/2) - puf1.height - 60, duration: 1});
            gsap.to(puf1, {delay:0.2, alpha: 1, duration: 0.5});
            gsap.to(puf1, {delay:1.5, alpha: 0, duration: 1 });
        }});
        tl.to(tsp, {angle: 0, duration: 1, ease: "circ.in"});
        tl.to(tsp, {angle: -4, duration: 1, ease: "circ.out", onStart:()=>{
            //-- puf 2
            gsap.set(puf2, {x:(window.innerWidth/2) - puf2.width/2, alpha:0 });
            gsap.to(puf2, {delay:0.2, x:(window.innerWidth/2) - puf2.width/2 - 90,  duration: 1});
            gsap.to(puf2, {delay:0.2, alpha: 1, duration: 0.5});
            gsap.to(puf2, {delay:1.5, alpha: 0, duration: 1 });
        }});
        tl.to(tsp, {angle: 0, duration: 1, ease: "circ.in"});

        
        this.cRef.current.appendChild(this.app.view)

        this.bubble.current.start(0.5)
    }
    componentWillUnmount() {
        if(this.app) {
            this.cRef.current.removeChild(this.app.view)
        }
    }

    render() {
        let mstyle = {}
        if(this.props.zIndex)mstyle.zIndex=this.props.zIndex
        return (
            <div className="lsanim" style={mstyle}>

                <SpeechBubble nr={2} className="speechbubbleLA dropshadow-bottom" ref={this.bubble}>
                    <div className="text">
                    just doing<br />
                    the final <br />
                    touches
                    </div>
                </SpeechBubble>

                <div className="canvaswrap" ref={this.cRef}></div>
            </div>
        )
    }
}

export default LoadingSpriteAnimation;