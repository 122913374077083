import React from 'react';
import { appApi } from './../store/appstore'
import { gsap, Power1 } from "gsap/dist/gsap";
import { isMobile } from 'mobile-device-detect';

class ColorTintSelectorBtn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.el = React.createRef();
    }

    componentDidMount() {
//        const w = window.innerWidth
    }
    componentWillUnmount() {
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevState.currentStep !== this.state.currentStep) {
            this.updateAnim()
        }
    }
    updateAnim() {
    }
    
    onClick(ev) {
        ev.preventDefault();
        appApi.getState().setSkinTone(this.props.nr);
        this.select();
        if(this.props.onSelect)this.props.onSelect(this.props.nr)
    }

    select() {
        if(this.props.nr === 1 ) gsap.to(this.el.current,{duration:0.2,x:-10,scale:1.2,marginTop:20,marginBottom:15});
        else gsap.to(this.el.current,{duration:0.2,x:-10,scale:1.2,marginTop:15,marginBottom:15});
    }
    deselect() {
//        console.log("deselect -> " + this.props.nr)
        if(this.props.nr === 1 ) gsap.to(this.el.current,{duration:0.2,x:0,scale:1,marginTop:20,marginBottom:10});
        else gsap.to(this.el.current,{duration:0.2,x:0,scale:1,marginTop:0,marginBottom:10});
    }
    
    render() {
        let cls = "tip"+this.props.nr
        let cls2 = "tail"+this.props.nr
        let mstyle = {}
        if(this.props.nr === 1 )mstyle = {marginTop:20}
        return (
            <a href="" onClick={this.onClick.bind(this)}>
                <div className='btn' style={mstyle} ref={this.el}>
                    <div className={cls}></div>
                    <div className={cls2}></div>
                </div>
            </a>
        )
    }
}

export default ColorTintSelectorBtn;
