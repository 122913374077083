import React from 'react';
//import { courseApi } from './../store/coursestore'
import browser from './browserdetect';

class CheckBoxLabel extends React.Component  {

    constructor(props) {
        super(props);
        this.state = {
            isManagingFocus: false,
            rollOver:false,
            toggle:-1,
            tabindex:1,
        };
        this.myRef = React.createRef();
        this.chk = React.createRef();
    }

    componentDidMount() {
        if( this.props.fadeIn )
            this.fadeIn()

        if( this.props.isChecked ) {
            this.setState({toggle:(this.props.isChecked?1:-1)})
        }
    }
    componentWillUnmount() {
    }
    
    _onBlur() {
        this._timeoutID = setTimeout(() => {
            if (this.state.isManagingFocus) {
                this.setState({
                    isManagingFocus: false,
                    rollOver: false,
                });
            }
        }, 0);
    }

    _onFocus() {
        clearTimeout(this._timeoutID);
        if (!this.state.isManagingFocus) {
            this.setState({
                isManagingFocus: true,
                rollOver: true,
            });
        }
    }

    _keypressHandler(ev) {
        console.log(ev.keyCode,ev.key)
        if( ev.key == " ") {
            this.myRef.current.click();
        }
    }

    _onClick(ev) {
        ev.preventDefault();
        if(this.props.fadeOut )
            this.fadeOut()

        let toggle = this.state.toggle;

        if( toggle === -1) {
            toggle = 1;
            this.setState({toggle:1})
        } else if(toggle === 1) {
            toggle = -1;
            this.setState({toggle:-1})
        }

        if(this.props.onClick)
            this.props.onClick(toggle);
        if(this.props.onMstate)
            this.props.onMstate("click");
    }

    _onMouseOver(ev) {

//        courseApi.getState().playSFX("sfxRollover")

        //-- show over image!
            this.setState({
                rollOver: true,
            });
        if(this.props.onMouseOver)
            this.props.onMouseOver();
        if(this.props.onMstate)
            this.props.onMstate("over");
    }
    _onMouseOut(ev) {
        //-- show normal image!
            this.setState({
                rollOver: false,
            });
        if(this.props.onMouseOut)
            this.props.onMouseOut();
        if(this.props.onMstate)
            this.props.onMstate("out");
    }

    
    render() {
        let cls = this.props.className
        let ariaHidden = "false"
        if( this.state.tabindex === -1 )
            ariaHidden = "true"
        
        let innerCls = "cbox"
        if(this.state.toggle === 1 ) {
            innerCls = "cbox-check"
        }
        return (
            <div  
                ref={this.myRef} 
                className={cls} 
                title={this.props.title} 
                onBlur={this._onBlur.bind(this)} 
                onFocus={this._onFocus.bind(this)} 
                aria-label={this.props.ariaLabel} 
                aria-hidden={ariaHidden} 
                role={this.props.role} 
                tabIndex={this.state.tabindex} 
                onClick={this._onClick.bind(this)} 
                onMouseOver={this._onMouseOver.bind(this)} 
                onMouseOut={this._onMouseOut.bind(this)} 
                >
                {this.props.caption}
                <div className={innerCls} ref={this.chk}></div>
            </div>
            )
    }
}

export default CheckBoxLabel;