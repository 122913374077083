import React,{ useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import { gsap } from "gsap/all";
import { useLoadApi } from '../store/preloaderstore'
import useWindowSize from "../utils/useWindowSize"
import StdBtnGenericText from '../components/StdBtnGenericText'

import SpeechBubble from '../components/SpeechBubble'

export default function StartPage() {
  let navigate = useNavigate();
  let location = useLocation();
  const size = useWindowSize();

  const page = useRef();
  const bubble1 = useRef();
  const man = useRef();
  const bottle = useRef();
  const sideTxt = useRef();
  const btnWrap = useRef();
  const btnStart = useRef();

  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }
  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    let dest = useLoadApi.getState().getDestURL()
    console.log(dest)
    let ww = window.innerWidth, wh = window.innerHeight, wf = ww/wh

    gsap.fromTo(man.current,{left:"100%"},{left:"0%"})
    bubble1.current.start(0.4);

    adjustIframeHeight()
  },[]);
  
  const adjustIframeHeight = () => {
    gsap.delayedCall(0.2,()=>{
      const ph = gsap.getProperty(page.current,"height")
      console.log(ph)
      if(window !== window.parent) gsap.set(window.parent,{"height":ph})
    })
  }

  const onStart = (ev) => {
    navigate("/video1")
  }

  return (
    <div className="page" ref={page}>
      <div className="start-top">
        <SpeechBubble nr={1} className="speechbubble1" ref={bubble1}>
          <div className="text">
          <span className="bigger">CIAO,</span>
          <br />
          ready to have your<br />
very first Moretti <br />
personalised bottle? 
          </div>
        </SpeechBubble>
        <img src="gfx/start-man.png" className="start-man-right" ref={man} />
      </div>
      <div className="start-mid">
        <div className="start-panel">
          
          <div className="text">
            In 1942 I coincindently met a nephew of Luigi Moretti in Trattoria Boschetti. He felt that my appearance embodied all of their values -  
            <span style={{color:"#b58500"}}>italianity, authenticity and playfulness</span> -  and convinced me to become Morreti’s proud brand image.
            <br />
            <br />
            Want to feel the same pride? 
            join me!
          </div>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="btnstartwrapper" ref={btnWrap}>
            <StdBtnGenericText className="btnstarthome" classNameExtra="dropshadow-bottom" caption="START" onClick={onStart} ref={btnStart} />
          </div>
          <br />
          <br />
        </div>

      </div>

      <div className="bg-img-blur"><img src="gfx/bg-blurred.webp" className="inner" /></div>

    </div>
  );
}

