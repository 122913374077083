import React,{ useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";

import { gsap } from "gsap/all";

import { useLoadApi } from '../store/preloaderstore'
import { useAppApi, appApi } from '../store/appstore'
import ManipulationSceneEndLabel from "../components/ManipulationSceneEndLabel"
import SpeechBubble from '../components/SpeechBubble'
import StdBtnDownloadOrShare from '../components/StdBtnDownloadOrShare'

import './ShowStories.css'

const axios = require('axios').default;
const ls = require('local-storage');


export default function ShowStoriesPageTest() {
  let navigate = useNavigate();

  const page = useRef(null);
  const mscene = useRef(null);
  const bgUnder = useRef(null);
  const bubble = useRef(null);
  const imageRef = useRef();
  
  const btnWrap = useRef(null);
  const btnPrev = useRef();
  const btnNext = useRef();

  const showbottleanim = useAppApi(state => state.showbottleanim)

  const [translateName,setTranslateName] = useState(true)
  const [hastransname, setHasTransName] = useState(true);
//  const [bdvalue, setBDValue] = useState();
  const [plname, setPLName] = useState("");
  const [pldal, setPLDal] = useState("");
  const [nameStory,setNameStory] = useState("")
  const [bdstory, setBDStory] = useState("");
  const [shareText,setShareText] = useState("")
  const [renderimg, setRenderImg] = useState();
  const style = useRef({top:Math.floor(window.screen.width*1.77)})

  const renderW = window.screen.width,
    renderH = Math.floor(renderW*1.77*1.12),
    renderMultiplier = 2

  useEffect(() => {
    let name = appApi.getState().playerName
    if( 0 === 1 ) {
      name = "PETER"
      appApi.getState().hasNameMatch("peter")
    }
    mscene.current.setNameText(name.toUpperCase() + " MORETTI")
    mscene.current.setYear(appApi.getState().playerYear)
    setPLName(name.toUpperCase() + " MORETTI")
    let plDate = appApi.getState().playerDate
    let normPLDate = plDate.split("-").reverse().join("-")
    setPLDal(normPLDate)

    let shareTextComplete = "An icon was born\n\n" + name.toUpperCase() + " MORETTI" + "\n\n DAL " + normPLDate + "\n\n\n"
    let bddata = appApi.getState().getBDStory(normPLDate)
//    console.log(bddata)
    let ndata = appApi.getState().curNameMatchData
    if( ndata !== null ) {
      setHasTransName(true)
      setNameStory(ndata.S)
      setBDStory(bddata.S)
      
      shareTextComplete +=  "Your beautiful italian name \n\n"  + ndata.S + " \n\n\ncelebrate your birthday the italian way \n\n" + bddata.S
      setShareText(shareTextComplete)
    } else {
      setHasTransName(false)
      setNameStory(bddata.S)

      shareTextComplete += "celebrate your birthday the italian way \n\n" + bddata.S
      setShareText(shareTextComplete)
    }
    setBGRemImage()
  },[]);

  useEffect(() =>{
    if(showbottleanim === -1 ) {
      console.log("ok now bubble")
      bubble.current.show()
    }
  },[showbottleanim])

  const adjustIframeHeight = () => {
    const ph = gsap.getProperty(page.current,"height")
    console.log(ph)
    if(window !== window.parent) gsap.set(window.parent,{"height":ph})
  }

  const setBGRemImage = () => {
    let imgd = useLoadApi.getState().imgData
    if(imgd) {
      try {
        mscene.current.createFromBase64( imgd )
        //-- get render?
        gsap.delayedCall(0.2,()=>{
          let finImg = mscene.current.getFinalCompositionImage()
          setRenderImg(finImg)
        })
      }catch(e) {
        alert(e)
      }
    } else {
      let dest=useLoadApi.getState().getDestURL(),uuid=ls.get("uuid"),u
      if( (""+uuid).length > 10 ) u=dest+"/getremfile/rem-"+uuid+".png"
      else if(window.location.href.indexOf("lentfert")>-1) u = 'http://lentfertnuc/bmlabel/testimg'
      axios.get(u,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        if(res.data) {
          if(res.data.hasOwnProperty("code") && res.data.code === 200 ) {
            mscene.current.createFromBase64( res.data.imgdata )
            //-- get render?
            gsap.delayedCall(0.2,()=>{
              let finImg = mscene.current.getFinalCompositionImage()
              setRenderImg(finImg)
            })
          }
        }
      }).catch((error) => {
        console.log(error);
        return error;
      })
    }
  }

/*
  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? window.atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)
    return new Blob([ia], { type: mimeString })
  }
*/
const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mimeType = arr[0].match(/:(.*?);/)[1],
    decodedData = atob(arr[1]),
    lengthOfDecodedData = decodedData.length,
    u8array = new Uint8Array(lengthOfDecodedData);
  while (lengthOfDecodedData--) {
    u8array[lengthOfDecodedData] = decodedData.charCodeAt(lengthOfDecodedData);
  }
  return new File([u8array], filename, { type: mimeType });
};

  //--
  //-- https://github.com/blueimp/JavaScript-Canvas-to-Blob
  //--
  const handleShare = async () => {
    const newFile = await dataURLtoFile(renderimg);
    const data = {
      files: [
        new File([newFile], 'image.png', {
          type: newFile.type,
        }),
      ],
      title: 'Image',
      text: shareText,
    };
    
    try {
      if (!navigator.canShare(data)) {
        console.error("Can't share");
      }
      await navigator.share(data);
    } catch (err) {
      console.error(err);
    }
  };
    
    
  const onPrevious = (ev) => {
    navigate("/editbirthdate")
  }
  const onNext = (ev) => {
  }

  const componentReady = (manO) => {
  }

  return (
    <div className="page" ref={page}>

      <SpeechBubble nr={2} className="speechbubbleLG dropshadow-bottom" ref={bubble}>
        <div className="text">
        you look<br />
        amazing!
        </div>
      </SpeechBubble>

      <img src={renderimg} style={{position:"relative",zIndex:100,left:"0px", width:"100%"}} ref={imageRef} />

      <ManipulationSceneEndLabel
        className="canvasstories" 
        showLabel={true} 
        yoffset={0} 
        renderBottle={1} 
        canvwidth={renderW*renderMultiplier} 
        canvheight={renderH*renderMultiplier} 
        renderMultiplier={renderMultiplier}
        componentReady={componentReady} 
        autoTouch={true}
        ref={mscene} 
      />
      
      <div className="bg-img-blur-2"><img src="gfx/bg-blurred.webp" className="inner" style={{marginTop:"-150px"}} /></div>

      {/* stories etc */}
      <StdBtnDownloadOrShare className="btn-dorshare dropshadow-bottom" onClick={handleShare} />

{ 1 === 1 && <>
      <div className="stories">
        <div className="nameTitle">an icon was born</div>
        <div className="plname">{plname}</div>
        <div className="pldal">{pldal}</div>
      </div>
      <div className="brownblock">
          {hastransname && <div className="celebratebb">Your beautiful<br />italian name</div>}
          {!hastransname && <div className="celebratebb">celebrate your birthday the italian way</div>}
          <br />
          <div className="celebrate-bodybb">{nameStory}</div>
      </div>
      {hastransname && <>
        <div className="celebrate">celebrate your birthday the italian way</div>
        <div className="celebrate-body">{bdstory}</div>
      </>}
</>}

      <img className="signature" src="gfx/signature.png" />
      <br />
      <br />
    </div>
  );
}
