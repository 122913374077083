import React from 'react';
import { loadApi } from './../store/preloaderstore'
import { gsap, Power1 } from "gsap/dist/gsap";
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js-legacy'

class RoundPanelTrans extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentStep:0,
        }

        this.oneTimeSetup()
    }

    //--
    //--    oneTimeSetup
    //--
    oneTimeSetup() {
        let diameter = 330,
            startAngle = 0, 
            align = "center", 
            textInside = false, 
            inwardFacing = true, 
            fName = "Arial", 
//            fName = "birra-title", 
            fSize = "86px", 
            fWeight = "bold", 
            kerning = -15,
            text = "MORETTI"

        this.diameter = diameter
        this.startAngle = startAngle * (Math.PI / 180); // convert to radians
        this.align = align.toLowerCase();
        this.textInside = textInside
        this.inwardFacing = inwardFacing
        this.mainCanvas = document.createElement('canvas');
        this.ctxRef = this.mainCanvas.getContext('2d');
        this.clockwise = align == "right" ? 1 : -1; // draw clockwise for aligned right. Else Anticlockwise
        this.kerning = kerning

        var div = document.createElement("div");
        div.innerHTML = text;
        div.style.position = 'absolute';
        div.style.top = '-10000px';
        div.style.left = '-10000px';
        div.style.fontFamily = fName;
        div.style.fontSize = fSize;
        div.style.fontWeight = fWeight;
        document.body.appendChild(div);
        var textHeight = div.offsetHeight;
        document.body.removeChild(div);
        this.textHeight = textHeight

        // in cases where we are drawing outside diameter,
        // expand diameter to handle it
        if (!textInside) this.diameter += this.textHeight * 2;
        
        this.mainCanvas.width = this.diameter;
        this.mainCanvas.height = this.diameter;
        // omit next line for transparent background
//        mainCanvas.style.backgroundColor = 'lightgray'; 
//        this.ctxRef.fillStyle = 'black';
        this.ctxRef.fillStyle = '#b12029';
        this.ctxRef.font = fWeight + " " + fSize + ' ' + fName;
        
        this.updateText(text)

        //-- pixi related?
//        this.textTexture = PIXI.Texture.from(this.mainCanvas);
    }

    //--
    //--    updateText
    //--
    updateText(text) {
        // Reverse letters for align Left inward, align right outward 
        // and align center inward.
        if (((["left", "center"].indexOf(this.align) > -1) && this.inwardFacing) || (this.align == "right" && !this.inwardFacing)) text = text.split("").reverse().join(""); 

        this.ctxRef.save();

        //-- clear prev stuff
        this.ctxRef.clearRect(0, 0, this.mainCanvas.width, this.mainCanvas.height);

        this.ctxRef.translate(0.5, 0.5);

        // Setup letters and positioning
        this.ctxRef.translate(this.diameter / 2, this.diameter / 2); // Move to center
        let startAngle = this.startAngle += (Math.PI * !this.inwardFacing); // Rotate 180 if outward
        this.ctxRef.textBaseline = 'middle'; // Ensure we draw in exact center
        this.ctxRef.textAlign = 'center'; // Ensure we draw in exact center

        // rotate 50% of total angle for center alignment
        if (this.align == "center") {
            for (var j = 0; j < text.length; j++) {
                var charWid = this.ctxRef.measureText(text[j]).width;
                startAngle += ((charWid + (j == text.length-1 ? 0 : this.kerning)) / (this.diameter / 2 - this.textHeight)) / 2 * -this.clockwise;
            }
        }

        // Phew... now rotate into final start position
        this.ctxRef.rotate(startAngle);

        // Now for the fun bit: draw, rotate, and repeat
        for (var j = 0; j < text.length; j++) {
            var charWid = this.ctxRef.measureText(text[j]).width; // half letter
            // rotate half letter
            this.ctxRef.rotate((charWid/2) / (this.diameter / 2 - this.textHeight) * this.clockwise); 
            // draw the character at "top" or "bottom" 
            // depending on inward or outward facing
            this.ctxRef.fillText(text[j], 0, (this.inwardFacing ? 1 : -1) * (0 - this.diameter / 2 + this.textHeight / 2));

            this.ctxRef.rotate((charWid/2 + this.kerning) / (this.diameter / 2 - this.textHeight) * this.clockwise); // rotate half letter
        }

        this.ctxRef.restore();

        //-- now update the texture so we can draw it!
//        this.textTexture.update()
    }

    //--
    //--    getCanvas
    //--
    getCanvas() {
        return this.mainCanvas
    }

    //--
    //--    getTexture
    //--
    getTexture() {
        return this.textTexture
    }

    render() {
        return (null)
    }
}

export default RoundPanelTrans;
