import React from 'react';
//import { loadApi } from './../store/preloaderstore'
import { appApi } from './../store/appstore'
import { gsap } from "gsap/dist/gsap";
import { isMobile } from 'mobile-device-detect';

import TotalStepsBottomItem from './TotalStepsBottomItem'

import './TotalStepsBottom.css';

class TotalStepsBottom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
//            currentStep:appApi.getState().tprogress,
            currentStep:this.props.currentStep,
            p1Text:"START",
            p2Text:"PHOTO",
            p3Text:"EDIT",
            p4Text:"FINISH",
        }
        
        this.el = React.createRef();
        
        this.p1 = React.createRef();
        this.p2 = React.createRef();
        this.p3 = React.createRef();
        this.p4 = React.createRef();

        this.line = React.createRef();

        this.spacings = 0;

        this._handleResize2 = this.handleResize2.bind(this)
    }
    
    componentDidMount() {
        window.addEventListener('orientationchange', this._handleResize2);
        this._handleResize2();
    }
    componentWillUnmount() {
        window.removeEventListener('orientationchange', this._handleResize2);
        if(this.unsub1)this.unsub1();
    }
    
    handleResize2(e) {
        this.makeBar()
        setTimeout(()=>{
            this.makeBar()
        },300)
    }
    makeBar() {
        if(this.unsub1)this.unsub1();
        const w = window.innerWidth
//        console.log("testing!")
        const margLR = 30;
        const bW = 20;
        let elW = parseFloat(gsap.getProperty(this.el.current,"width")) - bW
        this.spacings = elW / 3;
//        console.log(elW, this.spacings);
        this.p1.current.setPos(0)
        this.p1.current.setTextPos(11)

        this.p2.current.setPos(1*this.spacings)
        this.p2.current.setBasePos(5)
        this.p2.current.setTextPos(10)

        this.p3.current.setPos(2*this.spacings)
        this.p3.current.setBasePos(5)
        this.p3.current.setTextPos(10)

        this.p4.current.setPos(3*this.spacings)
        this.p4.current.setBasePos(10)
        this.p4.current.setTextPos(10)

        this.unsub1 = appApi.subscribe(currentStep => {
            this.setState({currentStep:currentStep})
        }, state => state.currentStep)

        this.updateLine()
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.currentStep !== this.state.currentStep) {
            this.updateAnim()
        }
    }
    updateLine() {
        let cs = this.state.currentStep;
//        console.log(cs, this.spacings, (cs-1)*this.spacings)
        gsap.set(this.line.current,{width:(cs-1)*this.spacings})
    }
    updateAnim() {

    }
    
    render() {

        return (
            <div className="tstepwrap">
                <div className="totalstep" ref={this.el}>

                    <TotalStepsBottomItem type={0} nr={1} currentStep={this.state.currentStep} ref={this.p1} caption={this.state.p1Text} />
                    <TotalStepsBottomItem type={0} nr={2} currentStep={this.state.currentStep} ref={this.p2} caption={this.state.p2Text} />
                    <TotalStepsBottomItem type={0} nr={3} currentStep={this.state.currentStep} ref={this.p3} caption={this.state.p3Text} />
                    <TotalStepsBottomItem type={0} nr={4} currentStep={this.state.currentStep} ref={this.p4} caption={this.state.p4Text} />

                    <div className='linebase'></div>
                    <div className='line' ref={this.line}></div>
                </div>
            </div>
        )
    }
}

export default TotalStepsBottom;
