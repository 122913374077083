import React,{ useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";

import { gsap } from "gsap/all";
import { v4 as uuidv4 } from 'uuid';

import { useLoadApi } from '../store/preloaderstore'
import { appApi } from '../store/appstore'
import ManipulationSceneCameraLabel from "../components/ManipulationSceneCameraLabel"

import StdBtnPrevArrow from '../components/StdBtnPrevArrow'
import StdBtnGenericText from '../components/StdBtnGenericText'
import TotalStepsBottom from '../components/TotalStepsBottom'
import BackgroundRoundedUnder from '../components/BackgroundRoundedUnder'
import SpeechBubble from '../components/SpeechBubble'
import TopSpace from '../components/TopSpace'

const axios = require('axios').default;
const ls = require('local-storage');

const birthday = new Date(1985, 11, 17);

export default function EditBirthDatePage() {
  let navigate = useNavigate();

  const page = useRef(null);
  const mscene = useRef(null);
  const bgUnder = useRef(null);
  const bubble2 = useRef(null);
  const badWbubble = useRef(null);
  const tpSpace = useRef(null);
  
  const btnWrap = useRef(null);
  const btnPrev = useRef();
  const btnNext = useRef();

  const [translateName,setTranslateName] = useState(true)
  const [tcolor,setTColor] = useState("#b58500")
  const [bdvalue, setBDValue] = useState(birthday);

  useEffect(() => {
    setBGRemImage()
    bgUnder.current.update()
    let name = appApi.getState().playerName
    mscene.current.setNameText(name.toUpperCase() + " MORETTI")
    mscene.current.setYear(appApi.getState().playerYear)
    if(appApi.getState().playerDate !== null) {
      setBDValue(appApi.getState().playerDate)
    }
//    badWbubble.current.show()
    tpSpace.current.update()
    adjustIframeHeight()
  },[]);

  const adjustIframeHeight = () => {
    const ph = gsap.getProperty(page.current,"height")
    console.log(ph)
    if(window !== window.parent) gsap.set(window.parent,{"height":ph})
  }

  const setBGRemImage = () => {
    let imgd = useLoadApi.getState().imgData
    if(imgd) {
      mscene.current.createFromBase64( imgd )
    } else {
      let dest=useLoadApi.getState().getDestURL(),uuid=ls.get("uuid"),u
      if( (""+uuid).length > 10 ) u=dest+"/getremfile/rem-"+uuid+".png"
      else if(window.location.href.indexOf("lentfert")>-1) u = 'http://lentfertnuc/bmlabel/testimg'
      axios.get(u,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        if(res.data) {
          if(res.data.hasOwnProperty("code") && res.data.code === 200 ) {
            mscene.current.createFromBase64( res.data.imgdata )
          }
        }
      }).catch((error) => {
        console.log(error);
        return error;
      })
    }
  }

  const onChangeBD = (e) => {
    let max_chars = 10;
    let bd = ""+e.target.value
    console.log(bd)
    let y = "0000"
    try {
      let s = bd.split("-")
      y = s[0];
    } catch(e) {
    }

    //-- store the year
    mscene.current.setYear(y)
    appApi.getState().playerYear = y

    appApi.getState().playerDate = bd
    setBDValue(bd);
  }

  const onPrevious = (ev) => {
    navigate("/editname")
  }
  const onNext = (ev) => {
    appApi.getState().setShowBottleAnimOL(1)

    //-- now render the label bigger and get the image data
    if( 0 === 1 && mscene.current ) {
      let imgData = mscene.current.createLabelImageBig()
      console.log(imgData)
    }
    //-- show overlay and than go to name stuff etc. 
    gsap.delayedCall(3.0,()=>{
      navigate("/showstories")
    })
    gsap.delayedCall(6.7,()=>{
      appApi.getState().setShowBottleAnimOL(-1)
    })
  }

  return (
    <div className="page" ref={page}>

      <SpeechBubble nr={2} className="speechbubbleBad dropshadow-bottom" ref={badWbubble}>
        <div className="text">
        oops,<br />
        let's keep it<br />
        friendly!
        </div>
      </SpeechBubble>

      <ManipulationSceneCameraLabel
        className="cwraplabel"
        showLabel={true} 
        yoffset={0} 
        renderBottle={0}
        canvwidth={window.screen.width}
        canvheight={Math.floor(window.screen.width*1.2)} 
        ref={mscene} 
      />
      
      <TopSpace className="" yOffset={-40} ref={tpSpace} />
      <div className="txtYourBirthDate">Your birthdate</div>
      <div className="name-input">
        <input type="date" className="birthday" pattern="\d{2}-\d{2}-\d{4}" placeholder="dd-mm-yyyy" min="1913-01-01" max="2005-01-01" onChange={onChangeBD} value={bdvalue} checked="" style={{color:tcolor}}/>
      </div>
      <div className="skintone-mid">
        <div className="btnstwrapper" ref={btnWrap}>
          <StdBtnPrevArrow className="btnprev dropshadow-bottom" caption="" onClick={onPrevious} ref={btnPrev} />
          <StdBtnGenericText className="btnnext dropshadow-bottom" caption="NEXT" onClick={onNext} ref={btnNext} />
        </div>
      </div>
      <TotalStepsBottom currentStep={3} />
      <br />
      <br />

      <BackgroundRoundedUnder ref={bgUnder} />
      <div className="bg-img-bottle"><img src="gfx/bottle-dripts-bg.webp" className="inner" style={{marginTop:"-150px"}} /></div>

    </div>
  );
}
