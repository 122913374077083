class BrowserDetect {
	constructor() {
        this._isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        this._isSafari =  /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

	  	this._isIpad = ['iPad'].includes(window.navigator.platform) || (window.navigator.userAgent.includes("Mac") && "ontouchend" in document)
	  	this._isIphone = ['iPhone','iPod'].includes(window.navigator.platform) || (window.navigator.userAgent.includes("Mac") && "ontouchend" in document)

        this._devicePixelRatio = parseFloat(window.devicePixelRatio);

//        alert(window.navigator.platform + " " + window.navigator.userAgent + " "+  navigator.standalone + " " + window.innerWidth + " " + window.innerHeight )
	}
	isIE11() {
		return this._isIE11;
	}
	isSafari() {
		return this._isSafari;
	}
	isIphone() {
		return this._isIphone;
	}
	isIpad() {
		return this._isIpad;
	}
	DPR() {
		return this._devicePixelRatio;
	}
}

const instance = new BrowserDetect();

export default instance;