import React from 'react';
import { isMobile } from 'mobile-device-detect';
import { gsap } from "gsap/all";


class CustomCanvasCamera extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        	numCameras:1,
        	currentFacingMode:"user",
        	videoSourceDeviceId :undefined,
        };

        this.video = React.createRef();
        this.stream = null
        this.camSettings = null

		this.stdRatio = 1920 / 1080;

//        this._handleResize = this.handleResize.bind(this)
//        this._handleResize2 = this.handleResize2.bind(this)
	}
	
    componentDidMount() {
    	if(this.props.active) {
			gsap.delayedCall(0.1,async ()=>{
				await this.initCameraStream()
			})
		}

		if( 0 === 1 ) {
			this.addListenerMulti(this.video.current,'abort canplay canplaythrough durationchange emptied encrypted ended error interruptbegin interruptend loadeddata loadedmetadata loadstart mozaudioavailable pause play playing progress ratechange seeked seeking stalled suspend timeupdate volumechange waiting', function(e){
				console.log(e.type, e);
			});
		}

		this._vPlaying = this.vidEVPlaying.bind(this)
		this._vError = this.vidEVError.bind(this)

		this.video.current.setAttribute("muted","");
		this.video.current.setAttribute("webkit-playsinline", "");
		this.video.current.setAttribute("playsinline", "");
		this.video.current.setAttribute("preload", "auto");

		this.video.current.addEventListener("playing", this._vPlaying);
		this.video.current.addEventListener("error", this._vError);
	}
    componentWillUnmount() {
		this.video.current.removeEventListener("playing", this._vPlaying);
		this.video.current.removeEventListener("error", this._vError);
		this._vPlaying = null;
		this._vError = null;
		
		if(this.stream)this.stream = null;
        if(this.camSettings)this.camSettings = null
    }

	stopBothVideoAndAudio(stream) {
	}

	vidEVPlaying(ev) {
		console.log(ev)
		if(this.props.onSuccess)this.props.onSuccess(this.camSettings)
	}
	vidEVError(ev) {
		window.alert(ev)
	}

	addListenerMulti(el, s, fn) {
		s.split(' ').forEach(e => el.addEventListener(e, fn, false));
	}
	vPlay(ev) {
		console.log(ev)
	}

//    handleResize(e) { }
//    handleResize2(e) { }

    //--
    //--	initCameraStream
    //--
	async initCameraStream() {
	  // stop any active streams in the window
	  if (this.stream) {
		this.stream.getTracks().forEach(track => {
			track.stop();
	    });
	  }

	  const constraints = {
	    audio: false,
	    video: {
			deviceId: this.state.videoSourceDeviceId ? { exact: this.state.videoSourceDeviceId } : undefined,
			facingMode: this.state.currentFacingMode,
			width: { ideal: 1920 },
			height: { ideal: 1920 },
	    },
	  };

	  if (navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
//		console.log(" 1 collecting mediaDevices via navigator")
		let devices = await navigator.mediaDevices.enumerateDevices()
		devices.forEach((device) => {
			console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
		});

//		console.log(" 2 collecting mediaDevices via navigator")
		try {
			navigator.mediaDevices.getUserMedia(constraints)
				.then( stream => {
					this.stream = this.handleSuccess(stream)
				})
				.catch(err => {
					this.handleError(err);
				});
		} catch (err) {
			console.error(err)
		}
	  } else {

	    const getWebcam = 
			navigator.getUserMedia || 
			navigator.webkitGetUserMedia || 
			navigator.mozGetUserMedia || 
			navigator.mozGetUserMedia || 
			navigator.msGetUserMedia;

	    if (getWebcam) {
			getWebcam(
				constraints,
				stream => {
					this.stream = this.handleSuccess(stream)
				},
				err => {
					this.handleError(err);
				},
			);
	    } else {
			if(this.props.onNotSupported)this.props.onNotSupported(true);
	    }
	  }
	}
	
	handleSuccess = (stream) => {

		console.log("we have a cam stream!")

		navigator.mediaDevices
		    .enumerateDevices()
		    .then(r => this.setNumberOfCameras(r.filter(i => i.kind === 'videoinput').length));

		let camSettings = null
		this.video.current.srcObject = stream;
		this.video.current.load();
//		this.video.current.play();
//		await new Promise(resolve => this.video.current.onloadedmetadata = resolve);
//		console.log("videoWidth:",this.video.current.videoWidth,"videoHeight:",this.video.current.videoHeight);

		stream.getTracks().forEach(track => {
			camSettings = track.getSettings();
			console.log(camSettings)
	    })
	    this.camSettings = camSettings;
		//-- add the aspect ratio as property, we need this later on when creating video texture!
		if( !this.camSettings.hasOwnProperty("aspectRatio")) {
			this.camSettings.aspectRatio = this.camSettings.width / this.camSettings.height
		}
		
		///-- not here, wait untill stream is playing!
//		if(this.props.onSuccess)this.props.onSuccess(camSettings)

		return stream;
	}

	handleError = (error) => {
		console.error(error);
		
		this.stream = null

		//https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
		if (error.name === 'PermissionDeniedError') {
			if(this.props.onPermissionDenied)this.props.onPermissionDenied(error.name,error.message);
		} else {
			if(this.props.onNotSupported)this.props.onNotSupported(error.name,error.message);
		}
	}
	
	setNumberOfCameras(nc) {
		this.setState({numCameras:nc})
		console.log("numCameras:",nc)
		if(this.props.onNumCameras)this.props.onNumCameras(nc)
	}

	scaleImage(img,w,h) {
		return new Promise((resolve, reject) => {
		  	img.onload = () => {
				//here you all code goes

				resolve(true)
		  	}
		})
	}

	getVideoRef() {
		return this.video.current
	}

	takePhoto() {
		var canvas = document.createElement('canvas');
		
		canvas.id = "CursorLayer";
		canvas.width = this.camSettings.width;
		canvas.height = this.camSettings.height;
		
		const playerWidth = this.camSettings.width || 1280;
		const playerHeight = this.camSettings.height || 720;
		const playerAR = playerWidth / playerHeight;
		
		const canvasWidth = this.camSettings.width || 1280;
		const canvasHeight = this.camSettings.height || 1280;
		const canvasAR = canvasWidth / canvasHeight;
		
		let sX, sY, sW, sH;
		
		if (playerAR > canvasAR) {
			sH = playerHeight;
			sW = playerHeight * canvasAR;
			sX = (playerWidth - sW) / 2;
			sY = 0;
		} else {
			sW = playerWidth;
			sH = playerWidth / canvasAR;
			sX = 0;
			sY = (playerHeight - sH) / 2;
		}
		
		const context = canvas.getContext('2d');
		if (context && this.video.current) {
			context.drawImage(this.video.current, sX, sY, sW, sH, 0, 0, sW, sH);
		}
		
		const imgData = canvas.toDataURL('image/jpeg');
		return imgData;
	}
	
    render() {

		let mirrored = this.state.currentFacingMode === 'user' ? true : false
		let fmode = (mirrored ? '180deg' : '0deg')
		let transform = "rotateY("+fmode+")"

        return (
        	<>
        		<div style={{position:"absolute",left:"0px",top:"0px",width:"100%",height:"100%"}}>
		            <video 
		            	id="wcamvideo" 
	            		autoPlay={true} 
	            		ref={this.video} 
	            		style={{position:"absolute",width:"100%",height:"100%",transform:transform,objectFit:"cover",opacity:1,zIndex:-1}} 
	//            		style={{position:"absolute",opacity:0,zIndex:0}} 
	            	/>
	            </div>
            </>
        )
    }
}

export default CustomCanvasCamera;