import React from 'react';
import { gsap } from "gsap/all";
import { appApi } from '../store/appstore'

import LoadingSpriteAnimation from './LoadingSpriteAnimation'

class VideoBackgroundCover extends React.Component {

    constructor(props) {
        super(props);
        this.img = React.createRef();
    }
    
    componentDidMount() {
//      gsap.set(this.img.current,{display:"none"})
    }
    componentWillUnmount() {
    }
    
    render() {
      return (<>
        <img className="video-bg" src="gfx/instruct-bg.jpg" ref={this.img} />
        <img className="video-bg-tile"  />
      </>)
    }
}

export default VideoBackgroundCover;
