import React from 'react';
import { loadApi } from './../store/preloaderstore'
import { gsap, Power1 } from "gsap/dist/gsap";
import { isMobile } from 'mobile-device-detect';

import './preloader.css';

class PreLoader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible:true,
            progress:0,
        }
        this.el = React.createRef();
        this.preLoader = React.createRef();
        this.colorRef = React.createRef();
        this.elPerc = React.createRef();
    }

    componentDidMount() {
//        gsap.set(this.elPerc.current,{y:307})
        
        loadApi.getState().init();

        this.unsub1 = loadApi.subscribe(progress => {
            this.setState({progress:progress})
        }, state => state.progress)
        
        this.unsub2 = loadApi.subscribe(complete => {
            this.fadeOutHide();
        }, state => state.complete)

        //-- make smaller!
        if( isMobile )
            gsap.set(this.preLoader.current,{scale:0.5})
    }
    componentWillUnmount() {
        this.removeHandlers();
    }
    
    removeHandlers() {
        try {
            this.unsub1();
            this.unsub2();
        } catch(e) {
            console.error(e);
        }
    }

    fadeOutHide() {
        gsap.to(this.el.current, 0.5, {delay:0.1,autoAlpha:0,onComplete:()=>{
            this.setState({visible:false})
            this.removeHandlers();
        }});
    }
    
    render() {
        if( this.state.visible) {
            return (
                <div className="pl-page" ref={this.el}>
                    <div className="pre-loader" ref={this.preLoader} >
                        <div className="wrapper">
                            <div className="loading-perc" tabIndex="0" ref={this.elPerc}>{this.state.progress+"%"}</div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (<></>
            )
        }
    }
}

export default PreLoader;
