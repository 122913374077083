import React from 'react';
import { useLoadApi } from './../store/preloaderstore'
import { appApi } from './../store/appstore'
//import { gsap, Power1 } from "gsap/dist/gsap";

const ls = require('local-storage');
const axios = require('axios').default;

class PreProcessing extends React.Component {

    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
        this.unsub1 = useLoadApi.subscribe(startotherapis => {
            this.startOtherApis()
        }, state => state.startotherapis)
    }
    componentWillUnmount() {
        this.unsub1();
    }

    startOtherApis() {
      this.checkApisV2()
    }

    //--
    //--  checkApisV1
    //--
    checkApisV1() {
      let uuid = ls.get("uuid")
      let dest = useLoadApi.getState().getDestURL()
      try {
        if( (""+uuid).length > 10 ) {
          let fn = uuid+".png";
          //-- checking if is person
          axios.get(dest+'/checkperson/'+fn).then(res => {
            console.log("checkperson:",res)
            let d = res.data
            if(d) {
              if(d.code === 200 ) {
                useLoadApi.getState().setIsPerson(d.isPerson)
                if( d.isPerson === -1 ) {
                  appApi.getState().setUploadErrorNumber(-10)
                }
              } else {
                useLoadApi.getState().setUploadErrorNumber(-10000)
              }
            }
            else {
              useLoadApi.getState().setUploadErrorNumber(-10000)
            }
          }).catch((error) => {
            console.log("checkperson -> error:",error);
            return error;
          })
          //-- checking age
          axios.get(dest+'/checkage/'+fn).then(res => {
            console.log("check age:",res)
            let d = res.data
            if(d) {
              if(d.code === 200 ) {
//                if(d.age < 18 - 4 ) {
                if(d.age < 14 ) {
                    useLoadApi.getState().setPersonAge(d.age)
                  appApi.getState().setUploadErrorNumber(-100)
                }
              }
            } else {
//              appApi.getState().setUploadErrorNumber(-10000)
            }
          }).catch((error) => {
            console.log("check age -> error:",error);
            return error;
          })
        }
      } catch(e) {
        console.log.error(e)
      }
    }
    
    //--
    //--  checkApisV2
    //--
    checkApisV2() {
      let uuid = ls.get("uuid")
      let dest = useLoadApi.getState().getDestURL()
      try {
        if( (""+uuid).length > 10 ) {
          let fn = uuid+".png";
          //-- checking if is person
          axios.get(dest+'/checkpersonandage/'+fn).then(res => {
            console.log("checkpersonandage:",res)
            let d = res.data
            if(d) {
              if(d.code === 200 ) {
                useLoadApi.getState().setIsPerson(d.isPerson)
                if( d.isPerson === -1 ) {
                  appApi.getState().setUploadErrorNumber(-10)
                }
                else {
                  //-- now checking age!
                  if(d.age < 18 ) {
                    useLoadApi.getState().setPersonAge(d.age)
                    appApi.getState().setUploadErrorNumber(-100)
                  }
                }
              } else {
                useLoadApi.getState().setUploadErrorNumber(-10000)
              }
            }
            else {
              useLoadApi.getState().setUploadErrorNumber(-10000)
            }
          }).catch((error) => {
            console.log("checkpersonandage -> error:",error);
            return error;
          })
        }
      } catch(e) {
        console.log.error(e)
      }
    }
    
    render() {
      return (<></>)
    }

}

export default PreProcessing;
