/*
    
    https://github.com/TJCoding/Enhanced-Image-Colour-Transfer-2
    
*/
import React from 'react';
import { gsap } from "gsap/all";
import * as PIXI from 'pixi.js-legacy'
//import hotkeys from 'hotkeys-js';
import { isMobile } from 'mobile-device-detect';
import { loadApi } from './../store/preloaderstore'
import { appApi } from './../store/appstore'

import { OutlineFilter } from 'pixi-filters';
import CustomCanvasCamera from "./../components/CustomCanvasCamera";

import './ManipulationSceneCamera.css';


const ls = require('local-storage');

class ManipulationSceneCamera extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showGrid:true,
        };

        this.cRef = React.createRef();
        this.ccc = React.createRef();

        if( isMobile ) {
            this.PConfig = {
                forceCanvas:true,
                backgroundAlpha:0,
                resolution: 1,
                antialias:true,
                autoResize:false,
                autoDensity: false,
                width:window.screen.width,
                height:window.screen.height,
            }
        } else {
            this.PConfig = {
                backgroundAlpha:0,
                resolution: 1,
                antialias:true,
                autoResize:true,
                autoDensity: true,
                resizeTo:window,
            }
        }

        this.app = new PIXI.Application(this.PConfig);
        
        this._noScroll = this.noScroll.bind(this)

        this._onDragStartPos = this.onDragStartPos.bind(this)
        this._onDragEndPos = this.onDragEndPos.bind(this)
        this._onDragMovePos = this.onDragMovePos.bind(this)

        this._onVirtualDragPos = this.onVirtualDragPos.bind(this)

        this.posP = null

        this._onDragStartRot = this.onDragStartRot.bind(this)
        this._onDragEndRot = this.onDragEndRot.bind(this)
        this._onDragMoveRot = this.onDragMoveRot.bind(this)

        this._onVirtualDragRot = this.onVirtualDragRot.bind(this)


        this._onDragStartSize = this.onDragStartSize.bind(this)
        this._onDragEndSize = this.onDragEndSize.bind(this)
        this._onDragMoveSize = this.onDragMoveSize.bind(this)

        this._onVirtualDragSize = this.onVirtualDragSize.bind(this)

        this.centerP1 = {x:0,y:0}

        this._handleResize = this.handleResize.bind(this)
        this._handleResize2 = this.handleResize2.bind(this)

        this.iLayer = null;
        this.eLayer = null;

        this.bmManWHRatio = 1;// 0.94765343

        this.camTextureCreated = null
        this.videoCircleMask = null;

        this.man = null;
        this.manHands = null;
        this.manO = null;

        //-- man offset center face
        this.manAncX = 0.54472;
        this.manAncY = 0.3174;
        
        this.calculatedYOffset = 80;
    }

    componentDidMount() {
        console.log("ManipulationSceneCamera -> componentDidMount")

        if(this.props.foto ) {
            loadApi.getState().imgRot = 0
            loadApi.getState().imgScale = 0
            loadApi.getState().imgPos = null
        
            loadApi.getState().moustacheRot = 0
            loadApi.getState().moustacheScale = 0
            loadApi.getState().moustachePos = null
        }

        try {
            this.createScaleRotateDragGrid()
            this.cRef.current.appendChild(this.app.view)

            if( this.props.autoTouch) {
                this.app.renderer.plugins.interaction.autoPreventDefault = false;
                this.app.view.style.touchAction = 'auto';
            }
        } catch(err){ console.error(err)}

        if(isMobile) {
            window.addEventListener('orientationchange', this._handleResize2);
        } else {
            window.addEventListener('resize', this._handleResize)
        }

        //-- do skintone!
        this.checkSkinTone();
//        this.setHeadImageOrMoustagePosIfNotSet()

        appApi.getState().setComponentReady()
        
        if(this.props.componentReady){
            console.log("calling this.props.componentReady")
            this.props.componentReady(loadApi.getState().manposition)
        }
    }
    componentWillUnmount() {
        if(isMobile) {
            window.removeEventListener('orientationchange', this._handleResize2);
        } else {
            window.removeEventListener('resize', this._handleResize)
        }
        if(this.app) {
            this.cRef.current.removeChild(this.app.view)
        }

        if(this.unsub1)this.unsub1()

        this.camTextureCreated = null
    }

    handleResize(e) {
//        api.getState().setScaleData(obj);
    };
    handleResize2(e) {
    }

    getPictureData() {
        const imageUrl = this.app.renderer.plugins.extract.base64();
        return imageUrl
    }

    createFromBase64(photo) {

//        let manO = loadApi.getState().manposition

        var image = new Image();
        image.src = photo
        image.onload = () => {

            this.photo.removeChildren()

            const base = new PIXI.BaseTexture(image);
            const texture = new PIXI.Texture(base);
            console.log(window.screen.width, " | ", base.width,base.height," texture.width:",texture.width," texture.height:",texture.height)

            this.myPic = texture

            let tw = texture.width,
                th = texture.height,
                ratio2 = window.screen.width / tw
            
            let myPic = new PIXI.Sprite(texture);
            myPic.width = tw
            myPic.height = th
            myPic.scale.x *= -1;
            myPic.x = tw/2
            myPic.y = -th/2
            
            console.log("myPic.width:",myPic.width," myPic.height:",myPic.height)
            //-- scale and set correct position
            let picScaledCont = new PIXI.Container()
            picScaledCont.addChild(myPic)
            //-- apply scaling factors, as in createCameraTexture
            picScaledCont.scale.x = picScaledCont.scale.y = ratio2 * loadApi.getState().cameraScaleFactor

            this.photo.addChild( picScaledCont )

    /*
            //-- round mask around image!
            let radius = Math.round((myPic.height/manO.scaleF)/2.4)
            let circ = new PIXI.Graphics()
    //        circ.lineStyle(2, 0xff0000, 1);
            circ.beginFill(0xff0000)
    //        circ.drawRect(0,0,200,200)
            circ.drawCircle(0,0,radius)
            circ.endFill()
    //        circ.visible = false;
            
    //        this.app.stage.addChild(circ)
            this.photo.addChildAt(circ)
            circ.x = this.iLayer.x
            circ.y = this.iLayer.y
            myPic.mask = circ
    */
        }
    }

    //--
    //--    createScaleRotateDragGrid
    //--
    createScaleRotateDragGrid() {
        let manO = null, 
            screenW = this.app.renderer.screen.width, 
            screenH = this.app.renderer.screen.height,
            yoff = 0, 
            w2 = screenW/2, 
            h2 = screenH/2

//        console.log("sw:",screenW,window.screen.width)

        if(this.props.yoffset)yoff = parseFloat(this.props.yoffset)

        this.iLayer = new PIXI.Container();
        this.iLayer.position.set( w2, h2 + yoff )

        var oLay = new PIXI.Container();
        oLay.position.set( w2, h2 + yoff )
        
        let oLayMask = new PIXI.Container();
        oLayMask.x = oLay.x
        oLayMask.y = oLay.y

        this.eLayer = new PIXI.Container();
        this.eLayer.x = this.iLayer.x
        this.eLayer.y = this.iLayer.y

        this.photo = new PIXI.Container();
        this.iLayer.addChild( this.photo )

        this.mLayer = new PIXI.Container();
        this.mLayer.x = this.iLayer.x
        this.mLayer.y = this.iLayer.y

        this.textLayer = new PIXI.Container();
        this.textLayer.pivot.set( 0.5, 0.5 )
        this.textLayer.x = this.iLayer.x
        this.textLayer.y = this.iLayer.y

        this.bubble = new PIXI.Container();
        

        let bmMan = null

        if(this.props.foto || this.props.showLabel || this.props.editfoto ) {
            bmMan = PIXI.Sprite.from(PIXI.Loader.shared.resources.bmMan3.texture)
            bmMan.anchor.set(this.manAncX, this.manAncY);

            bmMan.x = 0

            let bmanOW = bmMan.width,
                bmanOH = bmMan.height,
                bmanFact = bmanOW / bmanOH,
                manExtraSW = loadApi.getState().manExtraWidthRelWS

//            bmMan.alpha = 0.4

            let winW = screenW;
            //-- calculate man with depending on screen size and height!
            if( isMobile ) 
            {
                console.log("is mobile!")
                bmMan.width = screenW + manExtraSW
//                bmMan.height = bmMan.width
                bmMan.height = bmanOH * (bmMan.width/bmanOW)
                
                this.manScaleF = (bmMan.width/bmanOW)

//                loadApi.getState().manScaleFact1 = screenW / bmMan.width
                loadApi.getState().manScaleFact1 = (screenW + manExtraSW) / screenW
                console.log("manScaleFact1:",loadApi.getState().manScaleFact1)
//                console.log("man scale:",bmMan.scale.x,bmMan.scale.y)

                winW = screenW

                //-- calculate the top of man
                this.manLeftOC = bmMan.width * this.manAncX
                this.manTopOC = bmMan.height * this.manAncY
                this.manRightOC = bmMan.width - this.manLeftOC
                this.manBottomOC = bmMan.height - this.manTopOC

//                console.log("this.manLeft:",this.manLeftOC," this.manTop:",this.manTopOC," this.manRight:",this.manRightOC," this.manBottom:",this.manBottomOC)

                this.MLeft = w2 - this.manLeftOC;
                this.MTop = -h2 + this.manTopOC;
                this.MRight = w2 + this.manRightOC;
                this.MBottom = h2 + this.manBottomOC;

//                console.log("this.MLeft:",this.MLeft," this.MTop:",this.MTop," this.MRight:",this.MRight," this.MBottom:",this.MBottom)

                bmMan.y = this.MTop + this.calculatedYOffset;

                this.htmlMLeft = this.MLeft;
                this.htmlMTop = (h2 - this.manTopOC) + this.MTop + this.calculatedYOffset;
                this.htmlMRight = this.MRight;
                this.htmlMBottom = (-h2 + this.manTopOC) + this.MBottom + this.calculatedYOffset;

//                console.log("screenW:",screenW," screenH:",screenH)
//                console.log("manW:",bmMan.width," manH:",bmMan.height)

                console.log("this.htmlMLeft:",this.htmlMLeft," this.htmlMTop:",this.htmlMTop," this.htmlMRight:",this.htmlMRight," this.htmlMBottom:",this.htmlMBottom)
            }
            else
            {
                bmMan.width = screenW
                bmMan.height = bmMan.width/this.bmManWHRatio

                //-- calculate the top of man
                this.manLeftOC = bmMan.width * this.manAncX
                this.manTopOC = bmMan.height * this.manAncY
                this.manRightOC = bmMan.width - this.manLeftOC
                this.manBottomOC = bmMan.height - this.manTopOC

//                console.log("this.manLeft:",this.manLeftOC," this.manTop:",this.manTopOC," this.manRight:",this.manRightOC," this.manBottom:",this.manBottomOC)

                this.MLeft = w2 - this.manLeftOC;
                this.MTop = -h2 + this.manTopOC;
                this.MRight = w2 + this.manRightOC;
                this.MBottom = h2 + this.manBottomOC;

//                console.log("this.MLeft:",this.MLeft," this.MTop:",this.MTop," this.MRight:",this.MRight," this.MBottom:",this.MBottom)

                bmMan.y = this.MTop + this.calculatedYOffset;

                this.htmlMLeft = this.MLeft;
                this.htmlMTop = (h2 - this.manTopOC) + this.MTop;
                this.htmlMRight = this.MRight;
                this.htmlMBottom = (-h2 + this.manTopOC) + this.MBottom;
            }
            
            this.man = bmMan;

//            bmMan.filters = [ filter ];
            manO = {left:this.htmlMLeft,right:this.htmlMRight,top:this.htmlMTop,bottom:this.htmlMBottom,x:bmMan.x,y:bmMan.y,w:bmMan.width,h:bmMan.height,scaleF:bmMan.width / winW,olx:oLay.x,oly:oLay.y}
            loadApi.getState().setManPos(manO)
            this.manO = manO;
        }

        if(!this.props.foto ) {
            const bmMM = new PIXI.Graphics();
            bmMM.lineStyle(0);
            bmMM.beginFill(0xff0000);
            bmMM.lineTo(0,230)
            bmMM.lineTo(867,230)
            bmMM.lineTo(1038,317)
            bmMM.lineTo(1365,298)
            bmMM.lineTo(1365,651)
            bmMM.lineTo(1281,686)
            bmMM.lineTo(704,727)
            bmMM.lineTo(506,617)
            bmMM.lineTo(0,914)
            bmMM.scale.set(bmMan.scale.x) 
            bmMM.x = -(bmMan.width*this.manAncX)
            bmMM.y = -(bmMan.height*this.manAncY)   //-- use height if bmMan!
            oLayMask.addChild(bmMM)
            oLayMask.y += bmMan.y
        }

        let editRectW = 200, 
            editRectH = 200, 
            editRectW2 = editRectW/2, 
            editRectH2 = editRectH/2,
            editIconScale = 0.1

        let bmMoustache = null;
        if(this.props.editmoustache  || this.props.showLabel ) 
        {
            bmMoustache = PIXI.Sprite.from(PIXI.Loader.shared.resources.moustache.texture)
            bmMoustache.anchor.set(0.5, 0.5);
            bmMoustache.scale.set(0.3)
            bmMoustache.x = 0
            bmMoustache.y = 0

            editRectW = 100
            editRectH = 100
            editRectW2 = editRectW/2 
            editRectH2 = editRectH/2
            editIconScale = 0.05
        }

        var rect = new PIXI.Graphics()
        rect.lineStyle(2, 0x9d302e, 1);
        rect.drawRect(0,0,editRectW,editRectH)
        rect.position.set(-editRectW2,-editRectH2)
        rect.rotation = 0


        const gridGfx = PIXI.Sprite.from(PIXI.Loader.shared.resources.emptyPng.texture)
        gridGfx.position.set(-editRectW2,-editRectH2)
        gridGfx.width = editRectW
        gridGfx.height = editRectH
        gridGfx.interactive = true;
        gridGfx.on('mousedown', this._onDragStartPos  )
            .on('touchstart', this._onDragStartPos )
            .on('mouseup', this._onDragEndPos )
            .on('mouseupoutside', this._onDragEndPos )
            .on('touchend', this._onDragEndPos )
            .on('touchendoutside', this._onDragEndPos )
            .on('mousemove', this._onDragMovePos )
            .on('touchmove', this._onDragMovePos );

        const iconRot = PIXI.Sprite.from(PIXI.Loader.shared.resources.iconRot.texture);
        iconRot.anchor.set(0.5, 0.5);
        iconRot.position.set(editRectW2,-editRectH2)
        iconRot.scale.set(editIconScale,editIconScale)
//        iconRot.tint = 0x9d302e
        iconRot.interactive = true;
        iconRot.filters = [new OutlineFilter(2, 0xfdf6db)];
        iconRot.on('mousedown', this._onDragStartRot  )
            .on('touchstart', this._onDragStartRot )
            .on('mouseup', this._onDragEndRot )
            .on('mouseupoutside', this._onDragEndRot )
            .on('touchend', this._onDragEndRot )
            .on('touchendoutside', this._onDragEndRot )
            .on('mousemove', this._onDragMoveRot )
            .on('touchmove', this._onDragMoveRot );

        this.iconRot = iconRot;


        const iconSize = PIXI.Sprite.from(PIXI.Loader.shared.resources.iconSize.texture);
        iconSize.anchor.set(0.5, 0.5);
        iconSize.position.set(editRectW2,editRectH2)
        iconSize.scale.set(editIconScale,editIconScale)
        iconSize.interactive = true;
        iconSize.filters = [new OutlineFilter(2, 0xfdf6db)];
//        iconSize.tint = 0x9d302e
        iconSize.on('mousedown', this._onDragStartSize  )
            .on('touchstart', this._onDragStartSize )
            .on('mouseup', this._onDragEndSize )
            .on('mouseupoutside', this._onDragEndSize )
            .on('touchend', this._onDragEndSize )
            .on('touchendoutside', this._onDragEndSize )
            .on('mousemove', this._onDragMoveSize )
            .on('touchmove', this._onDragMoveSize );

        this.iconSize = iconSize
        
        if(this.props.foto || this.props.showLabel || this.props.editfoto) {
            oLay.addChild( bmMan )
        }
        if( this.props.editmoustache || this.props.showLabel) {
            this.mLayer.addChild( bmMoustache )
        }
        this.eLayer.addChild( gridGfx )
        this.eLayer.addChild( rect )
//        this.eLayer.addChild( center )
        this.eLayer.addChild( iconRot )
        this.eLayer.addChild( iconSize )

        //-- when editing the foto we need to 
        if( this.props.editfoto && manO) {
            this.iLayer.y += manO.y
            this.eLayer.y = this.iLayer.y
        }
        this.centerP1.x = this.eLayer.x
        this.centerP1.y = this.eLayer.y

        this.app.stage.addChild( this.iLayer )
        if(!this.props.foto ) this.app.stage.addChild( oLayMask )
        this.app.stage.addChild( oLay )

        if(!this.props.foto ) this.photo.mask = oLayMask

        //-- text layer
        this.app.stage.addChild( this.textLayer )

        this.app.stage.addChild( this.bubble )
        if( this.props.editmode ) {
            this.app.stage.addChild( this.eLayer )
        }
        //-- moustache layer
        this.app.stage.addChild( this.mLayer )

        //-- settings for the face!
        let newScale = loadApi.getState().imgScale
        if(!newScale)newScale = ls.get("headScale")
        if( newScale ) {
            this.iLayer.scale.set( newScale );
            if( this.props.editmoustache ) {
            } else {
                this.eLayer.scale.set( newScale );
            }
        }

        let rad = loadApi.getState().imgRot
        if(!rad)rad = ls.get("headRot")
        this.iLayer.rotation = rad;
        if( this.props.editmoustache ) {
            rad = loadApi.getState().moustacheRot
            this.mLayer.rotation = rad
        } else {
            this.eLayer.rotation = rad;
        }

        let imgPos = loadApi.getState().imgPos
        if(!imgPos)imgPos = ls.get("headPos")
        if( imgPos ) {
            this.iLayer.x = this.eLayer.x = imgPos.x
            this.iLayer.y = this.eLayer.y = imgPos.y
        }

        if( this.props.editmoustache || this.props.showLabel ) {

            //-- moustache

            newScale = loadApi.getState().moustacheScale
            if(!newScale)newScale = ls.get("moustacheScale")
            if( newScale ) {
                this.mLayer.scale.set( newScale );
                this.eLayer.scale.set( newScale );
            }

            rad = loadApi.getState().moustacheRot
            if(!rad)rad = ls.get("moustacheRot")
            this.eLayer.rotation = rad;
            this.mLayer.rotation = rad;
            
            imgPos = loadApi.getState().moustachePos
            if(!imgPos)imgPos = ls.get("moustachePos")
            if( imgPos ) {
                this.mLayer.x = this.eLayer.x = imgPos.x
                this.mLayer.y = this.eLayer.y = imgPos.y
            }
            else {
                this.eLayer.x += -(20*manO.scaleF)
                this.eLayer.y += (20*manO.scaleF)
                this.mLayer.x = this.eLayer.x
                this.mLayer.y = this.eLayer.y
            }

            this.centerP1.x = this.eLayer.x
            this.centerP1.y = this.eLayer.y
        } 

        return manO
    }

    checkSkinTone() {

        this.unsub1 = appApi.subscribe(skintone => {
            this.setSkinToneByNr(skintone)
        }, state => state.skintone)
        
        let skintone = appApi.getState().skintone
        this.setSkinToneByNr(skintone)
    }
    setSkinToneByNr(skintone) {
        if(this.man) {
            if( skintone === 1 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan1.texture;
            }
            if( skintone === 2 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan2.texture;
            }
            if( skintone === 3 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan3.texture;
            }
            if( skintone === 4 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan4.texture;
            }
            if( skintone === 5 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan5.texture;
            }
            if( skintone === 6 ) {
                this.man.texture = PIXI.Loader.shared.resources.bmMan6.texture;
            }
        }
    }
/*
    showBubbleTakePicture() {
        const c = new PIXI.Container();
        const bub = PIXI.Sprite.from(PIXI.Loader.shared.resources.bubTakePic.texture);

        console.log(this.manScaleF)
        bub.scale.set(this.manScaleF)
        c.addChild(bub)
        //-- add text
        c.x = 630 * this.manScaleF
        c.y = 70 * this.manScaleF
        this.app.stage.addChild( c )
    }
*/

    //--
    //--    showBubbleMoveScaleRotate
    //--
    showBubbleMoveScaleRotate() {
        const c = new PIXI.Container();
        const bub = PIXI.Sprite.from(PIXI.Loader.shared.resources.bubTakePic.texture);

        console.log(this.manScaleF)
        bub.anchor.set(0.5,0.9)
        bub.scale.set(this.manScaleF)

        let textStyle = new PIXI.TextStyle({
            fontFamily: 'birra-body',
            fontWeight: "normal",
            fontSize: 82 * this.manScaleF,
            fill: 0xfff9e0,
            align: 'center',
        });

        let txtbub = new PIXI.Text("MOVE SCALE,\nAND ROTATE", textStyle);
        txtbub.x = -176 * this.manScaleF
        txtbub.y = -235 * this.manScaleF
        txtbub.angle = 4;
        c.addChild(bub)
        c.addChild(txtbub)

        
        //-- add text
        c.x = 880 * this.manScaleF
        c.y = 320 * this.manScaleF
        gsap.fromTo(c.scale,{x:0,y:0},{delay:0.2,duration:1,x:1,y:1,ease:"elastic.out(1, 0.3)"})
        this.bubble.addChild( c )
        console.log(this.bubble.children.length)
    }

    //--
    //--    showBubblePlaceMoustage
    //--
    showBubblePlaceMoustage() {
        const c = new PIXI.Container();
        const bub = PIXI.Sprite.from(PIXI.Loader.shared.resources.bubPlaceMoustage.texture);

        console.log(this.manScaleF)
        bub.anchor.set(0.5,0.9)
        bub.scale.set(this.manScaleF)

        let textStyle = new PIXI.TextStyle({
            fontFamily: 'birra-body',
            fontWeight: "normal",
            fontSize: 82 * this.manScaleF,
            fill: 0xfff9e0,
            align: 'center',
        });

        let txtbub = new PIXI.Text("place your\nmoustache", textStyle);
        txtbub.x = -186 * this.manScaleF
        txtbub.y = -185 * this.manScaleF
        txtbub.angle = -9;
        c.addChild(bub)
        c.addChild(txtbub)

        
        //-- add text
        c.x = 849 * this.manScaleF
        c.y = 1320 * this.manScaleF
        gsap.fromTo(c.scale,{x:0,y:0},{delay:0.2,duration:1,x:1,y:1,ease:"elastic.out(1, 0.3)"})
        this.bubble.addChild( c )
        console.log(this.bubble.children.length)
    }

    noScroll(ev) {
        ev.preventDefault()
//        console.log(ev)
//        window.scrollTo(0, this.scrollPos)
    }

    lockScrollBody(s) {
        if(s === 1) {
//            this.app.renderer.plugins.interaction.autoPreventDefault = true;
//            this.app.view.style.touchAction = 'none';
            //            this.scrollPos = document.documentElement.scrollTop || document.body.scrollTop
//            window.addEventListener('scroll', this._noScroll)
            gsap.set("body",{"overflow":"hidden"})
        } else {

//            this.app.view.style.touchAction = 'auto';
//            this.app.renderer.plugins.interaction.autoPreventDefault = false;
            
            //            window.removeEventListener('scroll', this._noScroll)
            gsap.set("body",{"overflow":"auto"})
        }
    }

    //-- DRAG

    onDragStartPos(ev) {
        this.lockScrollBody(1)
//        console.log("onDragStartPos:",ev)
        this.posP = {x:ev.data.global.x,y:ev.data.global.y}
        this.initP = {x:this.eLayer.x,y:this.eLayer.y}
//        setTimeout(()=>{
        this.app.stage.interactive = true;
        this.app.stage.on('pointermove', this._onVirtualDragPos);
//        },100)
    }
    // Stop dragging feedback once the handle is released.
    onDragEndPos(ev) {
        this.lockScrollBody(-1)
//        console.log(e)
        this.app.stage.interactive = false;
        this.app.stage.off('pointermove', this._onVirtualDragPos);
    }
    onDragMovePos(ev) {
        this.onHandleMovedPos();
    }
    onHandleMovedPos() {
    }
    onVirtualDragPos(ev) {
//        console.log(this.posP, ev.data.global)
//        console.log(this.centerP1,ev.data.global)
//        let rad = this.getRadFromPoints( {x:this.centerP1.x,y:this.centerP1.y}, {x:ev.data.global.x,y:ev.data.global.y} )
        this.eLayer.x = this.initP.x + ev.data.global.x - this.posP.x;
        this.eLayer.y = this.initP.y + ev.data.global.y - this.posP.y;

        this.centerP1.x = this.eLayer.x
        this.centerP1.y = this.eLayer.y

        let dx = this.eLayer.x - this.manO.olx
        let dy = this.eLayer.y - this.manO.oly - this.manO.y

//        console.log(this.centerP1.x,this.centerP1.y," dx:",dx," dy:",dy)

        if( this.props.editmoustache ) {

            this.mLayer.x = this.eLayer.x
            this.mLayer.y = this.eLayer.y

            loadApi.getState().moustachePos = {x:this.centerP1.x,y:this.centerP1.y,dx:dx,dy:dy}

            ls.set("moustachePos",loadApi.getState().moustachePos)

        } else {

            this.iLayer.x = this.eLayer.x
            this.iLayer.y = this.eLayer.y

            loadApi.getState().imgPos = {x:this.centerP1.x,y:this.centerP1.y,dx:dx,dy:dy}

            ls.set("headPos",loadApi.getState().imgPos)
        }
    }
    
    //-- ROTATE

    onDragStartRot(ev) {
        this.lockScrollBody(1)
//        console.log(e)
        this.rotPStart = {x:ev.data.global.x,y:ev.data.global.y}
        let gpos = this.iconRot.toGlobal(this.app.stage.position)
        let rx = this.centerP1.x + this.iconRot.x
        let ry = this.centerP1.y + this.iconRot.y
        this.rotPCorner = {x:rx,y:ry}
        
//        console.log("this.eLayer.rotation:",this.eLayer.rotation)
        let initialRotation = this.eLayer.rotation
        let rad = this.getRadFromPoints( {x:this.centerP1.x,y:this.centerP1.y}, {x:this.rotPCorner.x,y:this.rotPCorner.y} )
        let radDiff = this.getRadFromPoints( {x:this.centerP1.x,y:this.centerP1.y}, {x:this.rotPStart.x,y:this.rotPStart.y} )
        const deg45 = Math.PI/4
        rad += deg45
        radDiff += deg45
        
        //-- rotation difference between start en corner angle + initial rotation
        this.radDiff = rad - radDiff + initialRotation
//        console.log(rad,radDiff)
        
        this.app.stage.interactive = true;
        this.app.stage.on('pointermove', this._onVirtualDragRot);
    }
    // Stop dragging feedback once the handle is released.
    onDragEndRot(ev) {
        this.lockScrollBody(-1)
//        console.log(e)
        this.app.stage.interactive = false;
        this.app.stage.off('pointermove', this._onVirtualDragRot);
    }
    onDragMoveRot(ev) {
        this.onHandleMovedRot();
    }
    onHandleMovedRot() {
    }
    onVirtualDragRot(ev) {
        const deg45 = Math.PI/4
        let rad = this.getRadFromPoints( {x:this.centerP1.x,y:this.centerP1.y}, {x:ev.data.global.x,y:ev.data.global.y} )
        rad += deg45 + this.radDiff
//        console.log(rad)
        this.eLayer.rotation = rad;

        if( this.props.editmoustache ) {
            
            this.mLayer.rotation = rad;

            loadApi.getState().moustacheRot = this.eLayer.rotation

            ls.set("moustacheRot",this.eLayer.rotation)
        } else {

            this.iLayer.rotation = rad;

            loadApi.getState().imgRot = this.eLayer.rotation

            ls.set("headRot",this.eLayer.rotation)
        }
    }
    
    //-- RESIZE

    onDragStartSize(ev) {
        this.lockScrollBody(1)

//        console.log(ev)
        this.sizePstart = {x:ev.data.global.x,y:ev.data.global.y}
        let rx = this.centerP1.x + this.iconSize.x
        let ry = this.centerP1.y + this.iconSize.y
        this.sizePCorner = {x:rx,y:ry}

        //-- take scale into account
        this.initScale = this.iLayer.scale.x
        if( this.props.editmoustache ) {
            this.initScale = this.mLayer.scale.x
        }
        let distToCorner = this.getDistFromPoints( {x:this.centerP1.x,y:this.centerP1.y}, {x:this.sizePCorner.x,y:this.sizePCorner.y} )
        let distToPoint = this.getDistFromPoints( {x:this.centerP1.x,y:this.centerP1.y}, {x:ev.data.global.x,y:ev.data.global.y} )
        
        distToCorner *= this.initScale
//        console.log(this.centerP1, " corner:", this.sizePCorner,ev.data.global, " initScale:",this.initScale, " distToCorner:",distToCorner)
        this.initSize = distToCorner
        this.sizeDiff = distToPoint - distToCorner

//        console.log(distToCorner,distToPoint,this.sizeDiff, this.iLayer.scale)

        this.app.stage.interactive = true;
        this.app.stage.on('pointermove', this._onVirtualDragSize);
    }
    // Stop dragging feedback once the handle is released.
    onDragEndSize(ev) {
        this.lockScrollBody(-1)

//        console.log(e)
        this.app.stage.interactive = false;
        this.app.stage.off('pointermove', this._onVirtualDragSize);
    }
    onDragMoveSize(ev) {
        this.onHandleMovedSize();
    }
    onHandleMovedSize() {
    }
    onVirtualDragSize(ev) {
//        console.log(ev)
//        console.log(this.centerP1, this.sizePstart, ev.data.global )
//        let gxy = ev.data.global
//        iconSize
        const dist = this.getDistFromPoints( this.centerP1, ev.data.global )
//        let correctDist = (dist - this.sizeDiff) - this.initSize
        let correctDist = (dist - this.sizeDiff)

//        console.log( dist, correctDist, correctDist/this.initSize )

        let newScale = (correctDist/this.initSize) * this.initScale
        this.eLayer.scale.set( newScale );

//        console.log("this.eLayer.scale:",this.eLayer.scale)

        if( this.props.editmoustache ) {
            
            this.mLayer.scale.set( newScale );
            
            loadApi.getState().moustacheScale = newScale

            ls.set("moustacheScale",newScale)
        } else {
            
            this.iLayer.scale.set( newScale );
            
            loadApi.getState().imgScale = newScale

            ls.set("headScale",newScale)
        }
    }

    getRadFromPoints(p1,p2) {
        // angle in radians
        const angleRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);
        return angleRadians
    }
    getDegFromPoints(p1,p2) {
        // angle in degrees
        const angleDeg = Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI;
        return angleDeg
    }
    getDistFromPoints(p1,p2) {
        const dx = (p2.x - p1.x)
        const dy = (p2.y - p1.y)
        const dist = Math.sqrt( (dx*dx )+(dy*dy) );
        return dist
    }

    //--
    //--    createCameraTexture
    //--
    async createCameraTexture(video,camSettings) {
        
        console.log("cameraReady - creating texture 1")
        
        try 
        {
            if( this.camTextureCreated === null ) {
                this.camTextureCreated = 1

                this.photo.removeChildren()

                console.log("cameraReady - creating texture 2")

                loadApi.getState().setCamSettings(camSettings)
                
    //            console.log(video)
                console.log(camSettings)

                let camTexture = null;
                camTexture = PIXI.Texture.from(video);
                var videoSprite = new PIXI.Sprite(camTexture);
                videoSprite.width = camSettings.width;
                videoSprite.height = camSettings.width/camSettings.aspectRatio;

                videoSprite.x = 0;
                videoSprite.y = 0;
                videoSprite.scale.x *= -1.0;
                videoSprite.x += videoSprite.width/2;
                videoSprite.y -= videoSprite.height/2;

                console.log(videoSprite,videoSprite.width,videoSprite.height," camSettings.width:",camSettings.width, " camSettings.aspectRatio:",camSettings.aspectRatio)

                let manO = this.manO //loadApi.getState().manposition
                var ratio = manO.w/videoSprite.width;
                let ratio2 = window.screen.width / videoSprite.width
                loadApi.getState().cameraTextureScaleFactor = ratio2
                console.log("scale ratio for video texture:",ratio, " ratio2:", ratio2 , " window.screen.width:", window.screen.width, " videoSprite.width:", videoSprite.width)
                let vcont = new PIXI.Container();
    //            vcont.scale.x = vcont.scale.y = ratio*0.7
                //-- use a global var for cameratexture scale factor
                vcont.scale.x = vcont.scale.y = ratio2 * loadApi.getState().cameraScaleFactor
                vcont.addChild(videoSprite);

                //-- add mask, no fixed size!
                //-- size of mask is related to MAN width
    //            let radius = Math.round(130)
                let radius = Math.round(400*ratio)
                let circ = new PIXI.Graphics()
                circ.beginFill(0xff0000)
                circ.drawCircle(0,0,manO.w*0.22)    //-- related to man width!
                circ.endFill()
                circ.x = this.photo.x
                circ.y = this.photo.y + manO.y 
    //            circ.scale.set(0,0)
                this.photo.addChildAt(circ)

                console.log(circ)

                vcont.mask = circ
                vcont.alpha = 0
                vcont.y = manO.y
    //            vcont..anchor.set(0.5);
                this.photo.addChild(vcont)

                gsap.to(vcont,{duration:0.9,alpha:1,
                    onUpdateParams:[circ,manO],onUpdate:(circ,manO)=>{
                        circ.x = this.photo.x
                        circ.y = this.photo.y + manO.y
                    },
                    onCompleteParams:[camTexture,circ,manO],onComplete:(camTexture,circ,manO)=>{
                        camTexture.update()
                        circ.x = this.photo.x
                        circ.y = this.photo.y + manO.y 
                        if(this.props.onCameraIsReady)this.props.onCameraIsReady(true)
                }})
    /*
                gsap.to(circ.scale,{duration:1,x:1,y:1,onCompleteParams:[videoSprite],onComplete:(vsprite)=>{
                    //-- from within here!
                    if(this.props.onCameraIsReady)this.props.onCameraIsReady(true)
                }})
    */
            }
            else {
                console.error("NO CAM TEXTURE")
            }
        } catch(exc) {
            window.alert(exc)
        }
    }

    takePhoto() {
        return this.ccc.current.takePhoto()
    }

    onPermissionDenied(errName,errMsg) {
        console.error("onPermissionDenied:")
        window.alert(errName + " - " + errMsg)
    }
    onNotSupported(errName,errMsg) {
        console.error("onNotSupported:")
        window.alert("Camera is not supported, use other device.")
    }
    onNumCameras(nc) {
        console.log("onNumCameras:",nc)
    }
    cameraReady(camSettings) {
        //-- find video element
//        const video = document.getElementById('wcamvideo');
        let video = this.ccc.current.getVideoRef()
        if(video) {
            this.createCameraTexture(video,camSettings)
        }
        else {
            window.alert(" ***** NO video id found!")
        }
    }
/*
    setNameCanvas(canv) {
        let texture = PIXI.Texture.from(canv);
        this.nameTextSprite = new PIXI.Sprite(texture)
        this.nameTextSprite.anchor.set(0.5, 0.5);
        this.textLayer.addChild(this.nameTextSprite)
//        this.app.stage.addChildAt(this.nameTextSprite)
    }
*/
    setNameText(text) {
        this._name = text
        this.updateNameTextLayer(text)
    }
    updateNameTextLayer(text) {
        if( !this.textCurve ) {
            this.textCurve = new PIXI.Container();
            
            this.textStyle = new PIXI.TextStyle({
                fontFamily: 'Arial',
                fontWeight: "bold",
                fontSize: 90,
                fill: 0xb12029,
                align: 'center',
            });

            this.textLayer.addChild(this.textCurve)
        }
        if(text) {
            this.textCurve.removeChildren()
            console.log("----------------------")
            let t = text,
                arr = text.split(''),
                totw = 0,
                diameter = 300,
                textHeight = 0,
                maxit = 200,
                steps = 800,
                startFontsize = 100,
                showDots = false;

            this.textStyle.fontSize = startFontsize
            while(true) {
                totw = 0
                for(let i=0;i<arr.length;i++) {
                    const tm = PIXI.TextMetrics.measureText(arr[i], this.textStyle);
                    if(tm.height > textHeight)textHeight = tm.height
                }
                //-- calc fit
                for(let i=0;i<arr.length;i++) {
                    const tm = PIXI.TextMetrics.measureText(arr[i], this.textStyle);
                    const w = tm.width, h = tm.height - tm.fontProperties.descent
                    let rad = (totw+(w/2))*Math.PI/steps
                    totw += w;
                }
                let rad = totw*Math.PI/steps
//                console.log("rad:",rad, Math.PI)
                if(rad<3.25){
                    console.log("this.textStyle.fontSize:",this.textStyle.fontSize)
                    break;
                }
                this.textStyle.fontSize -= 0.5
                maxit--
                if(maxit<=0) {
                    console.warn("max iterations")
                    break;
                }
            }

            totw = 0;
            let firstPointHW = 0
            const Holder = new PIXI.Container()
            for(let i=0;i<arr.length;i++) {
                const tm = PIXI.TextMetrics.measureText(arr[i], this.textStyle);
                const w = tm.width, h = tm.height - tm.fontProperties.descent
                console.log(""+arr[i],w, tm)

                const pS = new PIXI.Container()
                const pT = new PIXI.Text(arr[i], this.textStyle);
                pT.x = -w/2
                pT.y = -tm.fontProperties.descent

                if( 0 === 1 ) {
                    const rect2 = new PIXI.Graphics();
                    rect2.lineStyle(1, 0xFF0000);
                    rect2.drawRect(-w/2,-h, w, h);
                    pS.addChild(rect2)
                }
                pS.addChild(pT)

                if(firstPointHW === 0 ) {
                    firstPointHW = w/2
                }

                let rad = (totw+(w/2))*Math.PI/steps
                let x = diameter * Math.sin(rad);
                let y = diameter * -Math.cos(rad)
                pS.rotation = (totw + (w/2))*Math.PI/steps

                pS.x = x;
                pS.y = y;

                if( showDots ) {
                    var dot = new PIXI.Graphics();
                    dot.beginFill(1, 0xFF0000);
                    dot.drawCircle(x,y,2);
                    dot.endFill();
                    Holder.addChild(dot)
                }

                Holder.addChild(pS)

                totw += w;
            }
            Holder.rotation = -((totw+firstPointHW/2)/2*Math.PI/steps)
//            gsap.to(Holder,0.5,{rotation:-((totw+firstPointHW/2)/2*Math.PI/steps)})
            this.textCurve.addChild(Holder)

        }
    }

    updateNameTexture() {
        if(this.nameTextSprite)this.nameTextSprite.texture.update()
    }

    render() {
        let mstyle = {}
        if(this.props.zIndex)mstyle.zIndex=this.props.zIndex
        return (
            <>
                <div className={this.props.className} ref={this.cRef} style={mstyle}></div>
                <CustomCanvasCamera 
                    active={this.props.foto}
                    onPermissionDenied={this.onPermissionDenied.bind(this)}
                    onNotSupported={this.onNotSupported.bind(this)}
                    onNumCameras={this.onNumCameras.bind(this)}
                    onSuccess={this.cameraReady.bind(this)}
                ref={this.ccc} />
            </>
        )
    }
}

export default ManipulationSceneCamera;