import React,{ useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import { gsap } from "gsap/all";
//import { SplitText } from "gsap/SplitText";
import { useLoadApi } from '../store/preloaderstore'
import useWindowSize from "../utils/useWindowSize"
import TotalStepsBottom from '../components/TotalStepsBottom'
import ColorTintSelector from '../components/ColorTintSelector'

import LoadingSpriteAnimation from '../components/LoadingSpriteAnimation'

export default function Test02Page() {
  let navigate = useNavigate();
  let location = useLocation();
  const size = useWindowSize();

  useEffect(() => {
    let dest = useLoadApi.getState().getDestURL()
  },[size]);

  return (
    <div>
      
      <LoadingSpriteAnimation zIndex={2000} />

      <ColorTintSelector />
      <TotalStepsBottom />

    </div>
  );
}

