import React from 'react';
//import { courseApi } from './../store/coursestore'
import browser from './../components/browserdetect';
import btnIcon from "./gfx/btn-take-picture.png"
import { gsap } from "gsap/all";

class BtnTakePicture extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isManagingFocus: false,
            rollOver:false,
            blocked:false,
            tabindex:1,
        };
        this.myRef = React.createRef();
        this.btnImg = React.createRef();

        this.svg = React.createRef();
        this.svgCam = React.createRef();
    }

    componentDidMount() {
        if( this.props.fadeIn )
            this.fadeIn()

        if(this.props.startBlinking === 1 ) {
            this.startBlinkingAnim()
        }
        gsap.set(this.svg.current,{opacity:0.5})
        gsap.set(this.svgCam.current,{opacity:0})
    }
    componentWillUnmount() {
    }
    
    setBlocked(s) {
        this.setState({blocked:s})
    }

    setBtnState(s,del) {
        if(del === undefined)del = 0;
        if( s === 0) {
            this.setBlocked(true)
            gsap.set(this.btnImg.current,{scale:0.5,opacity:0.5})
        } 
        else if( s === 1 ) {
            this.setBlocked(false)
            gsap.to(this.btnImg.current,0.3,{delay:del,scale:1,opacity:1})
        }
    }

    anim(d) {
        if(d===undefined)d=parseFloat(0)
        gsap.to(this.svg.current,{delay:d,duration:0.4,scale:1})
        gsap.to(this.svg.current,{delay:d+0.4,duration:0.4,opacity:1})
        gsap.to(this.svgCam.current,{delay:d+0.5,duration:1,opacity:1})
    }
    
    _onBlur() {
        if( this.state.blocked ) return;
        this._timeoutID = setTimeout(() => {
            if (this.state.isManagingFocus) {
                this.setState({
                    isManagingFocus: false,
                    rollOver: false,
                });
            }
        }, 0);
    }

    _onFocus() {
        if( this.state.blocked ) return;
        clearTimeout(this._timeoutID);
        if (!this.state.isManagingFocus) {
            this.setState({
                isManagingFocus: true,
                rollOver: true,
            });
        }
    }

    _keypressHandler(ev) {
        if( this.state.blocked ) return;
        console.log(ev.keyCode,ev.key)
        if( ev.key == " ") {
            this.myRef.current.click();
        }
    }

    _onClick(ev) {
        ev.preventDefault();
        if( this.state.blocked ) return;

        if(this.props.fadeOut )
            this.fadeOut()

//        courseApi.getState().playSFX("sfxClick1")

        if(this.props.onClick)
            this.props.onClick(ev);
        if(this.props.onMstate)
            this.props.onMstate("click");
    }

    _onMouseOver(ev) {
        if( this.state.blocked ) return;

//        courseApi.getState().playSFX("sfxRollover")

        //-- show over image!
            this.setState({
                rollOver: true,
            });
        if(this.props.onMouseOver)
            this.props.onMouseOver();
        if(this.props.onMstate)
            this.props.onMstate("over");
    }
    _onMouseOut(ev) {
        if( this.state.blocked ) return;

        //-- show normal image!
            this.setState({
                rollOver: false,
            });
        if(this.props.onMouseOut)
            this.props.onMouseOut();
        if(this.props.onMstate)
            this.props.onMstate("out");
    }

    
    render() {
        let cls = this.props.className
        
        return (
            <a href="#" 
                ref={this.myRef} 
                className={cls} 
                title={this.props.title} 
                onBlur={this._onBlur.bind(this)} 
                onFocus={this._onFocus.bind(this)} 
//                onKeyPress={event => this._keypressHandler(event)} 
                aria-label={this.props.ariaLabel} 
                role={this.props.role} 
                tabIndex={this.state.tabindex} 
                onClick={this._onClick.bind(this)} 
                onMouseOver={this._onMouseOver.bind(this)} 
                onMouseOut={this._onMouseOut.bind(this)} 
                >
                <svg
                    viewBox="0 0 118 118"
                    ref={this.svg}
                >
                    <g ref={this.svgCam}>
                        <path
                        style={{
                            fill: "none",
                            stroke: "#ac2228",
                            strokeWidth: 5,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeMiterlimit: 10,
                        }}
                        d="M78.9 41h-8.4v-.5c0-2.6-2.1-4.7-4.7-4.7H51.3c-2.6 0-4.7 2.1-4.7 4.7v.5h-8.4c-3.4 0-6.1 2.7-6.1 6.1V72c0 3.4 2.7 6.1 6.1 6.1h40.6c3.4 0 6.1-2.7 6.1-6.1V47.1c.1-3.4-2.7-6.1-6-6.1z"
                        />
                        <circle
                        style={{
                            fill: "none",
                            stroke: "#ac2228",
                            strokeWidth: 5,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeMiterlimit: 10,
                        }}
                        cx={58.5}
                        cy={59.5}
                        r={10.6}
                        />
                        <circle
                        style={{
                            fill: "#ac2228",
                            stroke: "#ac2228",
                            strokeMiterlimit: 10,
                        }}
                        cx={76.8}
                        cy={48.9}
                        r={2.6}
                        />
                    </g>
                    <circle
                    style={{
                        fill: "none",
                        stroke: "#ac2228",
                        strokeWidth: 11,
                        strokeMiterlimit: 10,
                    }}
                    cx={59}
                    cy={59}
                    r={53.5}
                    />
                </svg>
            </a>
            )
    }
}

export default BtnTakePicture;