import React from 'react';
import { gsap, Power1 } from "gsap/dist/gsap";
import { isMobile, isTablet } from 'mobile-device-detect';

import './ScreenRotateMobile.css';

class ScreenRotateMobile extends React.Component {
    
    constructor() {
        super();
        this.state = {
            visible:false,
        }
    }
    
    componentDidMount() {
        if( isMobile || isTablet ) {
            this.h_checkScreenOrientation = this.checkScreenOrientation.bind(this);
            if ('onorientationchange' in window) {
                window.addEventListener('orientationchange', this.h_checkScreenOrientation);
            } else if ('onresize' in window) {
                window.addEventListener('resize', this.h_checkScreenOrientation);
            }
            this.checkScreenOrientation();
        }
    }
    componentWillUnmount() {
        if( isMobile || isTablet ) {
            if ('onorientationchange' in window) {
                window.removeEventListener('orientationchange', this.h_checkScreenOrientation);
            } else if ('onresize' in window) {
                window.removeEventListener('resize', this.h_checkScreenOrientation);
            }
        }
    }
    
    checkScreenOrientation() {
        let isLandscape = false;
        
        if( window.screen.width > window.screen.height ) {
            isLandscape = true;
        }
        switch(window.orientation) {  
          case -90: case 90:
            isLandscape = true;
            break; 
          default:
            isLandscape = false;
            break; 
        }
        console.info("WARNING isLandscape:",isLandscape, window.screen.width, window.screen.height)
        this.setState({visible:isLandscape});
    }

    render() {
        console.log("REDRAW!")
        if( this.state.visible) {
            return (
                <div className="screenRotMobile">
                <div className="">Please rotate youre device, this app is made for use in portrait mode only.</div>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }
}

export default ScreenRotateMobile;